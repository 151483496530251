import { tracking } from '@grid-is/tracking';

import { AnyCollaborator } from '@/api/collaborators';
import { ViewAccess } from '@/api/document';

import { EmailDomain, SharingContext } from '../machine/types';

type TrackingOption = 'domain_access' | 'link_access' | 'publishing' | 'password_protection' | 'embedding'
| 'conversations' | 'duplication' | 'more_options' | 'collaborator_list'| 'scenarios';

type TrackingOptionValue =
  'None'
  | 'View'
  | 'Edit'
  | 'Enabled'
  | 'Disabled'
  | 'Set'
  | 'Updated'
  | 'Collapsed'
  | 'Expanded';

export const collaboratorAccessToTrackingValue: Record<string, TrackingOptionValue> = {
  'view': 'View',
  'edit': 'Edit',
  'no-access': 'None',
};
export const collaboratorTypeToTrackingValue = {
  email: 'Email',
  user: 'User',
  group: 'User Group',
};

export function trackShareMenuInteraction (context: Pick<SharingContext, 'collaborators' | 'documentId'>, option: TrackingOption, newValue: TrackingOptionValue) {
  tracking.logEvent('Share Menu Interacted With', {
    document_id: context.documentId,
    option,
    option_changed_to: newValue,
    number_of_collaborating_entities: context.collaborators?.length ?? 0,
  });
}

export function haveCollaboratorsChanged (before: {emailDomain: EmailDomain, collaborators: AnyCollaborator[]}, after: {emailDomain: EmailDomain, collaborators: AnyCollaborator[]}) {
  if (
    before.emailDomain.access !== after.emailDomain.access ||
    before.emailDomain.domain !== after.emailDomain.domain ||
    before.collaborators.length !== after.collaborators.length
  ) {
    return true;
  }
  let hasChanged = false;
  before.collaborators.forEach((beforeC, i) => {
    const afterC = after.collaborators[i];
    if (beforeC.access !== afterC.access || beforeC.id !== afterC.id) {
      hasChanged = true;
      return false;
    }
  });
  return hasChanged;
}

function includesGroupCollaborator (collaborators: AnyCollaborator[]): boolean {
  return !!collaborators.find(c => c.type === 'group');
}

export function isOwnerOfGroupSharedWith (collaborators: AnyCollaborator[], currentUserId?: string): boolean {
  let isOwner = false;
  collaborators.forEach(c => {
    if (c.type === 'group' && c.creator_id === currentUserId) {
      isOwner = true;
      return true;
    }
  });
  return isOwner;
}

type ViewAccessTrackingProp = 'private' | 'private_with_collaborators' | 'private_embeddable' | 'public_unlisted' | 'public' | 'password';

export function getViewAccessProperty (access: ViewAccess, hasCollaborators: boolean, hasPassword: boolean, isEmbeddable: boolean): ViewAccessTrackingProp {
  if (access === 'private') {
    if (isEmbeddable) {
      return 'private_embeddable';
    }
    if (hasCollaborators) {
      return 'private_with_collaborators';
    }
    return 'private';
  }
  if (hasPassword) {
    return 'password';
  }
  if (access === 'public_unlisted') {
    return 'public_unlisted';
  }
  return 'public';
}
export function trackAddCollaborators (context: SharingContext) {
  const {
    documentId: document_id,
    isOpenedFromHome,
    viewAccess,
    collaborators = [],
    password,
    isEmbeddable,
    newUsers,
    newInvites,
    newGroups,
  } = context;
  const view_access = getViewAccessProperty(viewAccess, collaborators.length > 0, !!password, !!isEmbeddable);
  newInvites.forEach(() => {
    tracking.logEvent('Document Shared With', {
      document_id,
      is_unlisted: viewAccess === 'public_unlisted',
      target_type: 'Email',
      target_id: null,
      view_access,
      initiated_from: isOpenedFromHome ? 'Home - More Actions Menu' : 'In Document - Share Button',
    });
  });
  newUsers.forEach(({ id }) => {
    tracking.logEvent('Document Shared With', {
      document_id,
      is_unlisted: viewAccess === 'public_unlisted',
      target_type: 'User',
      target_id: id,
      view_access,
      initiated_from: isOpenedFromHome ? 'Home - More Actions Menu' : 'In Document - Share Button',
    });
  });
  newGroups.forEach(({ id }) => {
    tracking.logEvent('Document Shared With', {
      document_id,
      is_unlisted: viewAccess === 'public_unlisted',
      target_type: 'User Group',
      target_id: id,
      view_access,
      initiated_from: isOpenedFromHome ? 'Home - More Actions Menu' : 'In Document - Share Button',
    });
  });
}

export function trackSharingOnClose (context: SharingContext) {
  const {
    currentUserId,
    initialValues,
    documentId: document_id,
    emailDomain,
    isOpenedFromHome,
    isDocDuplicable,
    viewAccess,
    collaborators = [],
    password,
    isEmbeddable,
  } = context;
  if (!initialValues) {
    return;
  }
  if (initialValues.isDuplicable !== !!isDocDuplicable) {
    tracking.logEvent('Duplicable Setting Updated', {
      document_id,
      duplicable_setting: isDocDuplicable ? 'Duplicable' : 'Not Duplicable',
      duplicable_setting_before: initialValues.isDuplicable ? 'Duplicable' : 'Not Duplicable',
    });
  }
  if (initialValues.viewAccess !== viewAccess ||
    initialValues.isEmbeddable !== !!isEmbeddable ||
    initialValues.password !== password ||
    haveCollaboratorsChanged({ emailDomain: initialValues.emailDomain, collaborators: initialValues.collaborators }, { collaborators, emailDomain })
  ) {
    tracking.logEvent('Document Access Updated', {
      view_access: getViewAccessProperty(viewAccess, collaborators.length > 0, !!password, !!isEmbeddable),
      view_access_before: getViewAccessProperty(initialValues.viewAccess, initialValues.collaborators.length > 0, !!initialValues.password, initialValues.isEmbeddable),
      has_collaborators: collaborators.length > 0 || emailDomain.access,
      embed_setting: isEmbeddable ? 'Enabled' : 'Disabled',
      link_setting: viewAccess === 'private' ? 'No Access' : 'View',
      publish_setting: viewAccess === 'public' ? 'Enabled' : 'Disabled',
      is_password_protected: !!password,
      initiated_from: isOpenedFromHome ? 'Home - More Actions Menu' : 'In Document - Share Button',
      is_unlisted: viewAccess === 'public_unlisted',
      ...(includesGroupCollaborator(collaborators) && {
        sharer_is_owner_of_user_group_shared_with: isOwnerOfGroupSharedWith(collaborators, currentUserId),
      }),
    });
  }
}
