import csx from 'classnames';

import styles from './tag.module.scss';

export type TagProps = {
  label: string,
  className?: string,
  type?: 'pro'|'secondary',
}

export const Tag = ({ label, type, className }: TagProps) => (
  <span className={csx(styles.tag, className, type && styles[type])}>{label}</span>
);

