import { paths, schemas } from './generatedTypes';
import { getJSON, postJSON } from './request';

export type Collaborators = schemas['CollaboratorsResponse'];
export type EmailAccess = schemas['EmailAccess'];
export type DomainAccess = schemas['DomainAccess'];
export type GroupAccess = schemas['GroupAccessResponse'];
export type UserAccess = schemas['UserAccessResponse'];
export type AddCollaboratorsReqBody = paths['/document/{document_id}/collaborators/add']['post']['requestBody']['content']['application/json'];
export type RemoveCollaboratorsReqBody = paths['/document/{document_id}/collaborators/remove']['post']['requestBody']['content']['application/json'];
export type CollaboratorAccess = 'view' | 'comment' | 'edit';

type GetCollaboratorsResponse = paths['/document/{document_id}/collaborators']['get']['responses']['200']['content']['application/json'];

export function getCollaborators (documentId: string) {
  return getJSON<GetCollaboratorsResponse>(`/document/${documentId}/collaborators`);
}

export function addCollaborators (documentId: string, collaboratorsToAdd: AddCollaboratorsReqBody) {
  return postJSON<{}, AddCollaboratorsReqBody>(`/document/${documentId}/collaborators/add`, collaboratorsToAdd);
}

export function removeCollaborators (documentId: string, collaboratorsToRemove: RemoveCollaboratorsReqBody) {
  return postJSON<{}, RemoveCollaboratorsReqBody>(`/document/${documentId}/collaborators/remove`, collaboratorsToRemove);
}

type CollaboratorGroup = {
  access: CollaboratorAccess,
  type: 'group',
} & GroupAccess['group']
type CollaboratorUser = {
  access: CollaboratorAccess,
  type: 'user',
} & UserAccess['user']
type CollaboratorEmail = {
  access: CollaboratorAccess,
  type: 'email',
  id: string,
  email: string,
}
type CollaboratorEmailDomain = {
  access: CollaboratorAccess,
  type: 'emailDomain',
  id: string,
  domain: string,
}

export type AnyCollaborator =
  | CollaboratorGroup
  | CollaboratorUser
  | CollaboratorEmail
  | CollaboratorEmailDomain
