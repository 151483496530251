import { ReactNode } from 'react';
import csx from 'classnames';

import { RadioButton } from '@/grid-ui/RadioButton';

import styles from './RadioButtons.module.scss';

type Option = { value?: string, label: string | ReactNode, id?: string }

export type RadioButtonsProps = {
  name?: string,
  options: Option[],
  onChange?: (newValue: string) => void,
  value?: string | number,
  size?: 'small' | 'large',
  stack?: boolean,
  disabled?: boolean,
  testId?: string,
}

export function RadioButtons ({
  name,
  options = [],
  onChange = () => {},
  value,
  size,
  stack = false,
  disabled,
  testId = 'radio',
}: RadioButtonsProps) {
  return (
    <div
      data-testid={testId}
      className={csx(styles.container, stack && styles.stacked)}
      onChange={e => onChange((e.target as HTMLInputElement).value)}
      >
      {options.map((option, index) => {
        const optionId = option.id || `${name}-${index}`;
        return (
          <RadioButton
            id={optionId}
            key={optionId}
            value={option.value}
            label={option.label}
            name={optionId}
            checked={option.value === value}
            size={size}
            disabled={disabled}
            />
        );
      })}
    </div>
  );
}
