import { StateFrom, StateValueFrom } from 'xstate';

import { sharingMachine } from './SharingMachine';
import { SharingContext } from './types';

type SharingMachine = typeof sharingMachine;
export type SharingState = StateFrom<SharingMachine>;

export const isLoading = (state: SharingState) => {
  return state.hasTag('loading');
};

export const canGoBack = (state: SharingState) => {
  return state.hasTag('canGoBack');
};

export function isInState (name: StateValueFrom<SharingMachine>): ((s: SharingState) => boolean) {
  return (state: SharingState) => state.matches(name);
}

export const getCurrentState = (state: SharingState): SharingState => {
  return state;
};

export const canUseCollaborativeEditing = (state: SharingState) => {
  return state.context.enabledFeatures.can_use_collaborative_editing;
};

export function getFromContext<T extends keyof SharingContext > (attribute: T): ((s: SharingState) => SharingContext[T]) {
  return (state: SharingState) => state.context[attribute];
}

export function getValuesFromContext<T extends keyof SharingContext> (...attributes: Array<T>): ((s: SharingState) => Pick<SharingContext, T>) {
  return (state: SharingState) => {
    const values: Record<string, any> = {};
    attributes.forEach(attribute => {
      values[attribute] = state.context[attribute];
    });
    return values as Pick<SharingContext, T>;
  };
}

export const showCreateGroupHint = (state: SharingState) => {
  const newUsers = getFromContext('newUsers')(state);
  const newInvites = getFromContext('newInvites')(state);
  const newGroups = getFromContext('newGroups')(state);
  return newGroups.length === 0 && ((newUsers.length + newInvites.length) >= 2);
};

export const getInvitesLength = (state: SharingState) => {
  const newUsers = getFromContext('newUsers')(state);
  const newInvites = getFromContext('newInvites')(state);
  const newGroups = getFromContext('newGroups')(state);
  return newUsers.length + newInvites.length + newGroups.length;
};

export const getPropsForSharingPopover = (state: SharingState) => {
  return {
    isOpen: state.hasTag('isOpen'),
    isInEmbedModal: state.matches('Embed modal'),
    useModal: state.context.isOpenedFromHome,
    isReady: !state.matches('Unknown'),
  };
};
