import { Tutorial } from '.';

export const chartBuilder: Tutorial = {
  title: 'Pivot charts',
  description: 'Summarize, filter and group data from your databases',
  durationMinutes: 2,
  thumbnail: '/img/ob-tutorial-thumbnail-pivot-charts.png',
  outro: {
    title: 'Masterful 👏',
    body: <>You've learned how to use our powerful Chart Builder. Keep exploring GRID and see what you can do with data 😄</>,
    placement: 'center',
  },
  previewInstructions: [],
  instructions: [
    {
      name: 'Open Notion database',
      completion: 'click',
      mainTarget: 'workbook-tab-Sales CRM',
      title: 'Open the Notion database',
      body: 'We\'ve already connected a sample Notion database to your project. Click it to get started!',
      placement: 'bottom',
    },
    {
      name: 'Build a chart',
      completion: 'click',
      mainTarget: 'build-chart-button',
      title: 'Build a chart 🛠️',
      body: <>Let's create a visualization of this data. Click <strong>Build a chart</strong></>,
      placement: 'center',
    },
    {
      name: 'Build a Column chart',
      completion: 'click',
      mainTarget: 'option-menu-button-Column chart',
      title: 'Select Column chart',
      placement: 'center',
    },
    {
      name: 'Select Y-axis value',
      completion: 'click',
      mainTarget: 'Estimated Value',
      otherTargets: [ 'select-series-0' ],
      title: 'Let\'s do this 💪',
      body: <>We want to see how our salespeople are performing. Start by selecting <strong>Estimated Value</strong> for the Y-axis.</>,
      placement: 'bottom',
    },
    {
      name: 'Select X-axis value',
      completion: 'click',
      mainTarget: 'Sales Person',
      otherTargets: [ 'select-category-axis' ],
      title: 'Great!',
      body: <>Now select <strong>Sales Person</strong> for the X-axis.</>,
      placement: 'bottom',
    },
    {
      name: 'Select Y-axis math function',
      completion: 'click',
      mainTarget: 'sum',
      otherTargets: [ 'select-aggregator-0' ],
      title: 'Summit up ⛰️',
      body: <>Select <strong>sum</strong> to show the estimated value of each salesperson's accounts.</>,
      placement: 'bottom',
    },
    {
      name: 'Click Split By',
      completion: 'click',
      mainTarget: 'split-by',
      title: 'Looking good!',
      body: <>But there's more 🤓 Click <strong>Split by</strong> to see a breakdown of each salesperson's accounts.</>,
      placement: 'bottom',
    },
    {
      name: 'Select Split By value',
      completion: 'click',
      mainTarget: 'Company',
      otherTargets: [ 'select-split-by' ],
      title: 'It\'s that easy 😌',
      body: <>Select split by <strong>Company</strong>.</>,
      placement: 'bottom-left',
    },
    {
      name: 'Select Filter By',
      completion: 'click',
      mainTarget: 'filter-by',
      title: 'Ok, one last thing!',
      body: <>Click <strong>Filter by</strong> to look at the companies by salesperson.</>,
      placement: 'bottom-left',
    },
    {
      name: 'Select Filter By column',
      completion: 'click',
      mainTarget: 'Sales Person',
      otherTargets: [ 'select-filter-column-0' ],
      title: 'Two steps ✌️',
      body: <>First, select <strong>Sales Person</strong> for the column.</>,
      placement: 'bottom-left',
    },
    {
      name: 'Select Filter By value',
      completion: 'click',
      mainTarget: 'Lily Morgan',
      otherTargets: [ 'select-filter-value-0' ],
      title: '... and then',
      body: <>choose <strong>Lily Morgan</strong> as the value.</>,
      placement: 'bottom-left',
    },
  ],
};
