import { CellCSF } from '@grid-is/apiary/lib/csf';

import { EventStream } from '@/editor/EditorEventStream';

import { Tutorial } from '.';

/**
 * The editor does not support super speed so we need to slow down
 * inserting and selecting elements to make sure the editor has time to render
 * the elements before we continue to the next step
 */
function waitForEditor () {
  return new Promise(resolve => {
    const WAIT_FOR_EDITOR_TIMEOUT = 20;
    setTimeout(() => {
      resolve(true);
    }
    , WAIT_FOR_EDITOR_TIMEOUT);
  });
}

function insertParagraphAndWaitForAppearance (elementData) {
  EventStream.emit(EventStream.INSERT_PARAGRAPH, elementData);
  return waitForEditor();
}

async function selectElement (element: Element | null) {
  EventStream.emit(EventStream.SELECT_ELEMENT, element);
  return waitForEditor();
}

async function updateElement (element) {
  EventStream.emit(EventStream.UPDATE_ELEMENT_DATA, element);
  return waitForEditor();
}

const cells: Record<string, Partial<CellCSF>> = {
  A1: { f: 'IF($G$3>=ROW(),"Year " & ROW(),"")', v: 'Year 1' },
  A2: { f: 'IF($G$3>=ROW(),"Year " & ROW(),"")', v: 'Year 2' },
  A3: { f: 'IF($G$3>=ROW(),"Year " & ROW(),"")', v: 'Year 3' },
  A4: { f: 'IF($G$3>=ROW(),"Year " & ROW(),"")', v: 'Year 4' },
  A5: { f: 'IF($G$3>=ROW(),"Year " & ROW(),"")', v: 'Year 5' },
  A6: { f: 'IF($G$3>=ROW(),"Year " & ROW(),"")', v: '' },
  A7: { f: 'IF($G$3>=ROW(),"Year " & ROW(),"")', v: '' },
  A8: { f: 'IF($G$3>=ROW(),"Year " & ROW(),"")', v: '' },
  A9: { f: 'IF($G$3>=ROW(),"Year " & ROW(),"")', v: '' },
  A10: { f: 'IF($G$3>=ROW(),"Year " & ROW(),"")', v: '' },
  B1: { f: 'IF($G$3>=ROW(),$G$1*12*ROW(), "")', v: 3600, z: '"$"#,##0' },
  B2: { f: 'IF($G$3>=ROW(),$G$1*12*ROW(), "")', v: 7200, z: '"$"#,##0' },
  B3: { f: 'IF($G$3>=ROW(),$G$1*12*ROW(), "")', v: 10800, z: '"$"#,##0' },
  B4: { f: 'IF($G$3>=ROW(),$G$1*12*ROW(), "")', v: 14400, z: '"$"#,##0' },
  B5: { f: 'IF($G$3>=ROW(),$G$1*12*ROW(), "")', v: 18000, z: '"$"#,##0' },
  B6: { f: 'IF($G$3>=ROW(),$G$1*12*ROW(), "")', v: '', z: '"$"#,##0' },
  B7: { f: 'IF($G$3>=ROW(),$G$1*12*ROW(), "")', v: '', z: '"$"#,##0' },
  B8: { f: 'IF($G$3>=ROW(),$G$1*12*ROW(), "")', v: '', z: '"$"#,##0' },
  B9: { f: 'IF($G$3>=ROW(),$G$1*12*ROW(), "")', v: '', z: '"$"#,##0' },
  B10: { f: 'IF($G$3>=ROW(),$G$1*12*ROW(), "")', v: '', z: '"$"#,##0' },
  C1: { f: 'IF($G$3>=ROW(),B1*$G$2,"")', v: 216, z: '"$"#,##0' },
  C2: { f: 'IF($G$3>=ROW(),((C1+B2)*$G$2)+C1,"")', v: 660.96, z: '"$"#,##0' },
  C3: { f: 'IF($G$3>=ROW(),((C2+B3)*$G$2)+C2,"")', v: 1348.6176, z: '"$"#,##0' },
  C4: { f: 'IF($G$3>=ROW(),((C3+B4)*$G$2)+C3,"")', v: 2293.534656, z: '"$"#,##0' },
  C5: { f: 'IF($G$3>=ROW(),((C4+B5)*$G$2)+C4,"")', v: 3511.14673536, z: '"$"#,##0' },
  C6: { f: 'IF($G$3>=ROW(),((C5+B6)*$G$2)+C5,"")', v: '', z: '"$"#,##0' },
  C7: { f: 'IF($G$3>=ROW(),((C6+B7)*$G$2)+C6,"")', v: '', z: '"$"#,##0' },
  C8: { f: 'IF($G$3>=ROW(),((C7+B8)*$G$2)+C7,"")', v: '', z: '"$"#,##0' },
  C9: { f: 'IF($G$3>=ROW(),((C8+B9)*$G$2)+C8,"")', v: '', z: '"$"#,##0' },
  C10: { f: 'IF($G$3>=ROW(),((C9+B10)*$G$2)+C9,"")', v: '', z: '"$"#,##0' },
  D1: { f: 'IF($G$3>=ROW(),SUM(B1:C1),"")', v: '', z: '"$"#,##0' },
  D2: { f: 'IF($G$3>=ROW(),SUM(B2:C2),"")', v: '', z: '"$"#,##0' },
  D3: { f: 'IF($G$3>=ROW(),SUM(B3:C3),"")', v: '', z: '"$"#,##0' },
  D4: { f: 'IF($G$3>=ROW(),SUM(B4:C4),"")', v: '', z: '"$"#,##0' },
  D5: { f: 'IF($G$3>=ROW(),SUM(B5:C5),"")', v: '', z: '"$"#,##0' },
  D6: { f: 'IF($G$3>=ROW(),SUM(B6:C6),"")', v: '', z: '"$"#,##0' },
  D7: { f: 'IF($G$3>=ROW(),SUM(B7:C7),"")', v: '', z: '"$"#,##0' },
  D8: { f: 'IF($G$3>=ROW(),SUM(B8:C8),"")', v: '', z: '"$"#,##0' },
  D9: { f: 'IF($G$3>=ROW(),SUM(B9:C9),"")', v: '', z: '"$"#,##0' },
  D10: { f: 'IF($G$3>=ROW(),SUM(B10:C10),"")', v: '', z: '"$"#,##0' },
  F1: { v: 'Monthly savings' },
  F2: { v: 'Interest rate' },
  F3: { v: 'Saving period' },
  F4: { v: 'Total savings' },
  G1: { v: 300, z: '"$"#,##0' },
  G2: { v: 0.06, z: '0%' },
  G3: { v: 5 },
  G4: { v: '', z: '"$"#,##0' },
};

export const savingsCalculator: Tutorial = {
  title: 'Savings calculator',
  description: 'Turn your spreadsheet calculator into a shareable web doc.',
  durationMinutes: 2,
  thumbnail: '/img/ob-tutorial-thumbnail-savings-calculator.png',
  outro: {
    title: 'Great work 🥳',
    body: (<>Now that you've got the basics down, keep exploring Calculator Studio and see what you can do with data 😄</>),
    placement: 'center',
  },
  previewInstructions: [
    {
      name: 'Try the magic in GRID',
      completion: 'click',
      completionDelay: 2000,
      mainTarget: 'grid-slider-track',
      title: 'Try the magic in the Studio 🪄',
      body: 'This is a savings calculator based on spreadsheet calculations made in GRID. Try moving the slider and watch the savings projection update instantly.',
      gif: '/img/ob-gsv3-move-slider.gif',
      placement: 'bottom',
    },
  ],
  instructions: [
    {
      name: 'Start a project sheet',
      completion: 'click',
      mainTarget: 'start-from-scratch',
      title: 'Create a spreadsheet',
      body: (<>Click <strong>Start from scratch</strong> to add data to your calculator.</>),
      placement: 'bottom',
    },
    {
      name: 'Paste data',
      completion: 'click',
      completionDelay: 1000,
      mainTarget: 'paste-data-button',
      noBlur: true,
      clipboardCells: { cells, colWidths: { 5: 15 } },
      title: 'You\'re gonna need data 🤓',
      body: (<>We got this one. Just click <strong>Give me data</strong> and we'll paste in some data for you.</>),
      placement: 'center',
    },
    {
      name: 'Add area chart',
      completion: 'add-element',
      completionElement: 'area',
      mainTarget: 'Area chart',
      otherTargets: [ 'action-button-elements', 'element-option-Area chart' ],
      title: 'Add an Area chart',
      body: (<>You're building a savings calculator 💰 Let's visualize it 📈 Add the <strong>Area chart</strong> from the Element menu.</>),
      placement: 'center',
      doBefore: () => {
        const element = document.querySelector('.textblock.empty');
        EventStream.emit(EventStream.SELECT_ELEMENT, element);
        EventStream.emit(EventStream.INSERT_COLUMN_LAYOUT, [ '2/3', '1/3' ]);
      },
    },
    {
      name: 'Connect area chart',
      completion: 'value',
      completionDelay: 1000,
      completionValue: [ '=B1:C5', '=Sheet1!B1:C5', "='[Project sheet]'Sheet1!B1:C5" ],
      mainTarget: 'FORMULA-expr',
      otherTargets: [ 'sheet-content' ],
      lockFocus: true,
      autoFocus: true,
      title: 'Connect data to your chart',
      body: (<>With the <strong>Data</strong> field selected, click and drag to select the range <var>B1:C5</var> in the sheet.</>),
      gif: '/img/ob-salescalc-connect-chart.gif',
      placement: 'left',
      doBefore: async () => {
        const element = document.querySelector('[data-obid="grid-area"]');
        await selectElement(element);
        await updateElement({
          id: element?.id,
          data: {
            axisDim: {
              title: 'Years',
            },
            axisValue: {
              format: '0',
              max: '60000',
              type: 'linear',
            },
            blanks: 'gap',
            chartColors: '={"#d3aa17","#ffd53d"}',
            format: '"$"#,##0',
            interpolate: 'linear',
            legend: '={"Savings", "Interest"}',
            stacked: 'true',
            type: 'area',
          },
        });
      },
    },
    {
      name: 'Submit your selection',
      completion: 'click',
      mainTarget: 'element-hero-continue-button',
      title: 'Submit your selection',
      body: (<>Click <strong>Continue</strong> to submit your data selection.</>),
      placement: 'top-right',
    },
    {
      name: 'Add a slider',
      completion: 'add-element',
      completionElement: 'slider',
      mainTarget: 'Slider',
      otherTargets: [ 'action-button-elements', 'element-option-Slider' ],
      title: 'Add a slider',
      body: (<>Let's add some interactivity to your project. Find the <strong>Slider</strong> in the Element menu.</>),
      placement: 'center',
      doBefore: async () => {
        await selectElement(document.querySelectorAll('[data-type="col"]')[1]);
      },
    },
    {
      name: 'Connect slider',
      completion: 'value',
      completionDelay: 1000,
      completionValue: [ '=G1', '=Sheet1!G1', "='[Project sheet]Sheet1'!G1" ],
      mainTarget: 'FORMULA-expr',
      otherTargets: [ 'sheet-content', 'target-cell-suggestions' ],
      lockFocus: true,
      autoFocus: true,
      title: 'Connect your slider',
      body: (<>With <strong>Target cell</strong> selected, choose <var>G1</var> as the data reference for your slider.</>),
      gif: '/img/ob-gsv3-connect-slider.gif',
      placement: 'left',
    },
    {
      name: 'Submit your selection',
      completion: 'click',
      mainTarget: 'element-hero-continue-button',
      title: 'Submit your selection',
      body: (<>Click <strong>Continue</strong> to submit your data selection.</>),
      placement: 'top-right',
    },
    {
      name: 'Add more sliders',
      completion: 'click',
      mainTarget: 'ob-continue',
      title: 'Nice work 👏',
      body: 'We\'ll add two more sliders for you before you continue to the next step.',
      placement: 'center',
      noActionRequired: true,
      doBefore: async () => {
        const element = document.querySelector('[data-obid="grid-slider"]');
        await updateElement({
          id: element?.id,
          data: {
            format: '"$"#,##0',
            max: '1000',
            min: '10',
            step: '10',
            title: '=F1',
            type: 'slider',
            width: 'full',
          },
        });
      },
    },
    {
      name: 'Preview',
      completion: 'click',
      mainTarget: 'edit-toggle',
      title: 'Ready to rock? 🪨',
      body: (<>Click <strong>View</strong> see how your finished project will look to viewers.</>),
      placement: 'center',
      doBefore: async () => {
        EventStream.emit(EventStream.CLOSE_ELEMENT_OPTIONS);
        const textBlock1 = document.querySelectorAll('[data-obid="grid-slider"]')[0];
        EventStream.emit(EventStream.SELECT_ELEMENT, textBlock1);
        await insertParagraphAndWaitForAppearance({
          elements: [
            {
              type: 'grid:inline',
              data: {
                type: 'slider',
                expr: '=G3',
                max: '5',
                min: '1',
                step: '1',
                title: '=F3',
                format: '# "years"',
                width: 'full',
              },
            },
          ],
        });
        await selectElement(document.querySelectorAll('[data-obid="grid-slider"]')[1]);
        await insertParagraphAndWaitForAppearance({
          elements: [
            {
              type: 'grid:inline',
              data: {
                type: 'slider',
                expr: '=G2',
                max: '0.2',
                min: '0.01',
                step: '0.01',
                title: '=F2',
                width: 'full',
              },
            },
          ],
        });
      },
    },
    {
      name: 'Interact with slider',
      completion: 'click',
      completionDelay: 1000,
      completionConfetti: true,
      mainTarget: 'grid-slider-track',
      title: 'Try moving your slider',
      body: 'Watch your savings projection update instantly when you interact with the slider.',
      gif: '/img/ob-gsv3-move-slider.gif',
      placement: 'bottom-right',
    },
  ],
};
