import { useContext } from 'react';
import { useSelector } from '@xstate/react';
import csx from 'classnames';

import { Icon, IconTypes } from '@grid-is/icon';

import { Button } from '@/grid-ui/Button';
import { SelectInline } from '@/grid-ui/SelectInline';
import { Tooltip } from '@/grid-ui/Tooltip';

import { getFromContext } from './machine/selectors';
import { SharingStateContext } from './machine/SharingStateContext';

import styles from './sharingOptions.module.scss';

export const LinkSharing = () => {
  const { service } = useContext(SharingStateContext);
  const { send } = service;
  const currentAccess = useSelector(service, getFromContext('viewAccess'));

  return (
    <div className={styles.sharingOption} data-testid="linkSharing">
      <Tooltip label="Allow anyone you share the link with to access this doc." placement="left" hideOnElementClick delay={1200} wrapElement wrapClassName={styles.tooltipWrap}>
        <>
          <Icon name="link" size={24} className={csx(styles.icon, currentAccess !== 'private' && styles.enabled)} />
          <div className={styles.wrap} data-obid="link-sharing">
            <span>People with the link</span>
            <SelectInline
              // TODO: handle public
              value={currentAccess === 'private' ? 'no-access' : 'view'}
              onChange={({ value }) => {
                send('SET_DOCUMENT_ACCESS', { newAccess: value === 'no-access' ? 'private' : 'public_unlisted' });
              }}
              items={[
                {
                  label: 'have no access',
                  value: 'no-access',
                  icon: 'window-close' as IconTypes,
                },
                {
                  label: 'can view',
                  value: 'view',
                  icon: 'eye' as IconTypes,
                },
              ]}
              />
          </div>
        </>
      </Tooltip>
      <div className={styles.buttons}>
        <Button buttonType="secondary" onClick={() => send('COPY_LINK')} className={styles.button} data-obid="copy-link-button">Copy link</Button>
      </div>
    </div>
  );
};
