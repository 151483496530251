import { useMemo } from 'react';

import {
  AnyCollaborator,
} from '@/api/collaborators';
import { Avatar, AvatarProps } from '@/grid-ui/Avatar';
import { AvatarCentipede, AvatarType } from '@/grid-ui/AvatarCentipede';

import { CollaboratorAccessControl } from './CollaboratorAccessControl';

import styles from '../sharingContent.module.scss';

export type CollaboratorProps = {
  collaborator: AnyCollaborator,
}

export const Collaborator: React.VFC<CollaboratorProps> = ({
  collaborator: c,
}) => {
  const avatarProps: AvatarProps = {
    className: styles.avatar,
    size: 32,
  };
  if (c.type === 'group') {
    avatarProps.username = c.name;
  }
  else if (c.type === 'user') {
    avatarProps.name = c.name;
    avatarProps.avatarUrl = c.avatar_url;
  }
  else if (c.type === 'email') {
    avatarProps.username = c.email;
  }
  else if (c.type === 'emailDomain') {
    avatarProps.name = c.domain;
    avatarProps.avatarUrl = `https://logo.clearbit.com/${c.domain}?size=64`;
  }

  const avatars: AvatarType[] = useMemo(() => {
    return c.type === 'group' ? c.users.map(u => ({
      name: u.name,
      username: u.username,
      avatar_url: u.avatar_url,
    })) : [];
  }, [ c ]);

  function renderName () {
    if (c.type === 'emailDomain') {
      return 'Anyone on your domain';
    }
    else if (c.type === 'email') {
      return c.id;
    }
    else {
      return c.name;
    }
  }

  return (
    <div key={c.id} className={styles.collaborator}>
      <Avatar isGroup={c.type === 'group'} {...avatarProps} />
      <div className={styles.text}>
        <span className={styles.name}>
          {renderName()}
        </span>
        {c.type === 'group' ? (
          <span className={styles.userName}>
            <AvatarCentipede size={16} max={5} avatars={avatars} />
          </span>
        ) : (
          <span className={styles.userName}>
            {c.type === 'user' && `@${c.username}`}
            {c.type === 'email' && c.email}
            {c.type === 'emailDomain' && `People with an @${c.domain} email address`}
          </span>
        )}
      </div>
      <CollaboratorAccessControl collaborator={c} currentAccess={c.access} />
    </div>
  );
};
