import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from '@xstate/react';
import csx from 'classnames';

import { IconTypes } from '@grid-is/icon';
import { tracking } from '@grid-is/tracking';

import { CollaboratorAccess } from '@/api/collaborators';
import { Avatar } from '@/grid-ui/Avatar';
import { Button } from '@/grid-ui/Button';
import { ConfirmationModal } from '@/grid-ui/Confirmation';
import { FieldWrapper } from '@/grid-ui/FieldWrapper';
import { Input } from '@/grid-ui/Input';
import { Pill } from '@/grid-ui/Pill';
import { SelectInline } from '@/grid-ui/SelectInline';

import { canUseCollaborativeEditing, getFromContext, isInState } from './machine/selectors';
import { SharingStateContext } from './machine/SharingStateContext';
import { SharingHeader } from './SharingHeader';

import styles from './CreateGroup.module.scss';

export function CreateGroup () {
  const { service } = useContext(SharingStateContext);
  const { control, handleSubmit, formState: { errors } } = useForm<{ groupName: string}>();

  const [ access, setAccess ] = useState<CollaboratorAccess>('view');
  const { send } = service;
  const newUsers = useSelector(service, getFromContext('newUsers'));
  const newInvites = useSelector(service, getFromContext('newInvites'));
  const invitesLength = newUsers.length + newInvites.length;
  const isConfirmingLeave = useSelector(service, isInState('Create group.Confirm leave creating group'));
  const canGiveEditingRights = useSelector(service, canUseCollaborativeEditing);

  useEffect(() => {
    if (errors.groupName) {
      tracking.logEvent('User Group Creation Error Shown', { initiated_from: 'Share Menu' });
    }
  }, [ errors.groupName ]);

  function changeAccess (selected) {
    setAccess(selected.value);
  }

  function onSubmit (data) {
    send('CREATE_GROUP_AND_SEND_INVITES', { access, groupName: data.groupName });
  }

  return (
    <div className={styles.container}>
      <SharingHeader heading="Share with people and groups" canGoBack />
      <div className={styles.pillsWrapper}>
        <div className={csx(styles.pills)} data-testid="groupPills">
          {newUsers.map(user => (
            <div key={user.id} className={styles.pillWrapper}>
              <Pill
                text={user.name}
                onDismiss={() => {
                  const users = newUsers.filter(u => u.id !== user.id);
                  send('UPDATE_COLLABORATORS', { newUsers: users, newInvites });
                  if (!users.length && !newInvites.length) {
                    send('FORCE_GO_BACK');
                  }
                }}
                icon={<Avatar name={user.name} username={user.username} avatarUrl={user.avatar_url} size={16} />}
                />
            </div>
          ))}
          {newInvites.map(invite => (
            <div key={invite} className={styles.pillWrapper}>
              <Pill
                text={invite}
                onDismiss={() => {
                  const invites = newInvites.filter(i => i !== invite);
                  send('UPDATE_COLLABORATORS', { newUsers, newInvites: invites });
                  if (!newUsers.length && !invites.length) {
                    send('FORCE_GO_BACK');
                  }
                }}
                />
            </div>
          ))}
        </div>
        <div className={styles.rights}>
          <SelectInline
            value={access}
            onChange={changeAccess}
            items={[
              {
                label: 'View',
                value: 'view',
                icon: 'eye' as IconTypes,
              },
              {
                label: 'Edit',
                value: 'edit',
                icon: 'pencil' as IconTypes,
                hasProPill: !canGiveEditingRights,
                href: !canGiveEditingRights ? '/settings/billing' : undefined,
              },
            ]}
            />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper label="Group name" hasError={!!errors.groupName} helperText={errors.groupName ? 'Please give your group a name' : ''}>
          <Controller
            name="groupName"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <Input
                placeholder="Name your group"
                hasError={!!error}
                {...field}
                autoFocus
                />
            )}
            />

        </FieldWrapper>
        <div className={styles.button}>
          <Button
            type="submit"
            buttonType="primary"
            >
            Send {invitesLength === 1 ? 'invite' : 'invites'} and create group
          </Button>
        </div>
      </form>
      {isConfirmingLeave &&
        <ConfirmationModal
          title="Discard invites?"
          message="Are you sure you want to discard your invites?"
          onConfirm={() => send('CONFIRM')}
          onCancel={() => {
            send('CANCEL');
          }}
          />
      }
    </div>
  );
}
