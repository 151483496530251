import { ReactNode, useContext, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';

import { Icon, IconTypes } from '@grid-is/icon';

import { EventStream } from '@/editor/EditorEventStream';
import { Button } from '@/grid-ui/Button';
import { Input } from '@/grid-ui/Input';
import { TextLink } from '@/grid-ui/TextLink';
import { ToggleButton } from '@/grid-ui/ToggleButton';
import { Tooltip } from '@/grid-ui/Tooltip';
import { isMobile } from '@/utils';

import { getValuesFromContext } from './machine/selectors';
import { SharingStateContext } from './machine/SharingStateContext';
import { SharingHeader } from './SharingHeader';
import { trackShareMenuInteraction } from './util/trackingUtils';

import styles from './MoreOptions.module.scss';

type OptionHeaderProps = {
  title: string,
  iconName: IconTypes,
  action?: ReactNode,
}

function OptionHeader ({ title, iconName, action }: OptionHeaderProps) {
  return (
    <div className={styles.header}>
      <span className={styles.title}>
        <Icon name={iconName} className={styles.icon} size={16} />
        {title}
      </span>
      {action}
    </div>
  );
}

type OptionInfoProps = {
  text: string,
  tooltip?: string,
  action?: ReactNode,
}

function OptionInfo ({ text, tooltip, action }: OptionInfoProps) {
  return (
    <div className={styles.info}>
      <Tooltip label={tooltip} placement="left" disabled={!tooltip}>
        <span>{text}</span>
      </Tooltip>
      {action}
    </div>
  );
}

function Option ({ children }: {children: ReactNode}) {
  return (
    <div className={styles.option}>
      {children}
    </div>
  );
}

export function MoreOptions () {
  const { service } = useContext(SharingStateContext);
  const { send } = service;

  const {
    viewersCanSaveScenarios,
    isDocDuplicable,
    viewAccess,
    password,
    documentId,
    collaborators,
    isOpenedFromHome,
  } = useSelector(service, getValuesFromContext(
    'viewersCanSaveScenarios',
    'isDocDuplicable',
    'viewAccess',
    'password',
    'documentId',
    'collaborators',
    'isOpenedFromHome'));
  const viewAccessPublic = viewAccess === 'public';
  const [ newPassword, setNewPassword ] = useState(password);
  const [ showPassword, setShowPassword ] = useState(!!password);

  function onNeedHelpClick () {
    EventStream.emit(EventStream.OPEN_HELP_CENTER, { helpDoc: 'Sharing your project' });
  }

  useEffect(() => {
    setNewPassword(password);
    setShowPassword(!!password);
  }, [ password ]);

  return (
    <div className={styles.container}>
      <div className={styles.moreOptionHeader}>
        <SharingHeader heading="More options" canGoBack />
        {!isOpenedFromHome && !isMobile() && <TextLink onClick={onNeedHelpClick}>Need help?</TextLink>}
      </div>
      <Option>
        <OptionHeader
          title="Publish"
          iconName="globe"
          action={
            <ToggleButton
              size="small"
              onChange={() => {
                send('SET_DOCUMENT_ACCESS', { newAccess: viewAccessPublic ? 'private' : 'public' });
              }}
              value={viewAccessPublic}
              testId="toggle-public"
              />}
          />
        <OptionInfo text="Make this content public and discoverable on the web" />
      </Option>

      <Option>
        <OptionHeader
          title="Password protection"
          iconName="lock"
          action={
            <ToggleButton
              size="small"
              onChange={() => {
                trackShareMenuInteraction({ documentId, collaborators }, 'password_protection', showPassword ? 'Disabled' : 'Enabled');
                if (showPassword) {
                  setNewPassword('');
                  if (password?.length) {
                    // only update the document password if it truly changed
                    send('SET_DOCUMENT_PASSWORD', { password: '' });
                  }
                }
                setShowPassword(!showPassword);
              }}
              value={showPassword}
              testId="toggle-password"
              />}
          />
        {!showPassword && <OptionInfo text="Protect this project with a password" />}
        {showPassword && (
          <form
            onSubmit={e => {
              e.preventDefault();
              send('SET_DOCUMENT_PASSWORD', { password: newPassword });
            }}
            className={styles.passwordInput}
            >
            <Input
              name="password"
              placeholder="Choose a password"
              value={newPassword}
              onChange={setNewPassword}
              variant="lite"
              size="small"
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              />
            <Button
              buttonType="tertiary"
              type="submit"
              disabled={password === newPassword}
              >
              Save password
            </Button>
          </form>
        )
       }
      </Option>

      <Option>
        <OptionHeader title="Other settings" iconName="cog" />
        <OptionInfo
          text="Enable viewers to save scenarios"
          tooltip="Allow viewers to save scenarios in this doc."
          action={
            <ToggleButton
              size="small"
              onChange={() => {
                send('SET_VIEWERS_CAN_SAVE_SCENARIOS');
              }}
              value={viewersCanSaveScenarios}
              testId="toggle-scenarios"
              />}
          />
        <OptionInfo
          text="Allow others to copy this project and its data"
          tooltip="Allow anyone with access to copy this project and its data."
          action={<ToggleButton
            size="small"
            onChange={() => {
              send('SET_IS_DUPLICABLE');
            }}
            value={isDocDuplicable}
            testId="toggle-duplicatable"
            />}
          />
      </Option>
    </div>
  );
}
