import { useContext } from 'react';

import { Icon } from '@grid-is/icon';

import { SharingStateContext } from './machine/SharingStateContext';

import styles from './sharingHeader.module.scss';

type SharingHeaderProps = {
  heading: string,
  canGoBack?: boolean,
}

export const SharingHeader = ({ heading, canGoBack }: SharingHeaderProps) => {
  const { service } = useContext(SharingStateContext);
  const { send } = service;

  if (canGoBack) {
    return (
      <div className={styles.sharingHeader}>
        <button className={styles.goBackButton} type="button" onClick={() => send('GO_BACK')} data-testid="go-back">
          <Icon name="angle" direction="left" size={24} className={styles.icon} />
          <span>{heading}</span>
        </button>
      </div>
    );
  }

  return (
    <div className={styles.sharingHeader}>
      <span>{heading}</span>
    </div>
  );
};
