import { EmbedOption } from './EmbedOption';
import { LinkSharing } from './LinkSharing';

import styles from './sharingOptions.module.scss';

export function SharingOptions () {
  return (
    <div className={styles.sharingOptions}>
      <EmbedOption />
      <LinkSharing />
    </div>
  );
}

