import { ReactElement } from 'react';
import ReactDOM from 'react-dom';

import { Button } from '@/grid-ui/Button';
import { Modal } from '@/grid-ui/Modal';

import styles from './confirmation.module.scss';

type ConfirmationProps = {
  title: string,
  message: string | ReactElement | JSX.Element, // Allows for either a single string, or multiple paragraphs.
  type?: 'constructive' | 'destructive',
  confirmLabel?: string,
  cancelLabel?: string,
  onConfirm?: () => void,
  onCancel?: () => void,
  onClose?: () => void,
}

export const ConfirmationModal = ({ title, message, type = 'destructive', confirmLabel = 'Confirm', cancelLabel = 'Cancel', onConfirm, onCancel, onClose }: ConfirmationProps) => {
  return (
    <Modal
      open
      size="small"
      className={styles.confirmation}
      header={title}
      onClose={onClose}
      obId="confirmation-dialog"
      overlayClassName={styles.overlay}
      >
      {message}
      <div className={styles.buttons}>
        <Button buttonSize="large" buttonType={type === 'constructive' ? 'tertiary' : 'destructive'} onClick={onCancel}>{cancelLabel}</Button>
        <Button buttonSize="large" buttonType={type === 'constructive' ? 'primary' : 'destructive'} onClick={onConfirm} data-obid="confirmation-button">{confirmLabel}</Button>
      </div>
    </Modal>
  );
};

export function confirmation ({ title, message, type, confirmLabel, cancelLabel, onConfirm = () => {}, onCancel = () => {} }: ConfirmationProps) {
  const element = document.getElementById('confirmation-root');

  function cleanup () {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  function confirm () {
    cleanup();
    onConfirm();
  }

  function cancel () {
    cleanup();
    onCancel();
  }

  if (element) {
    ReactDOM.render(
      <ConfirmationModal
        title={title}
        message={message}
        type={type}
        confirmLabel={confirmLabel}
        cancelLabel={cancelLabel}
        onConfirm={confirm}
        onCancel={cancel}
        onClose={cleanup}
        />, element);
  }
}
