import { forwardRef, Ref, useImperativeHandle } from 'react';
import { useMachine } from '@xstate/react';
import csx from 'classnames';

import { OutsideClickHandler } from '@grid-is/outside-click-handler';

import { LoadingToastContext, loadingToastMachine } from './LoadingToastMachine';

import styles from './loadingToast.module.scss';

export type LoadingToastRef = {
  load: () => void,
  error: (props: LoadingToastContext) => void,
  success: (props: LoadingToastContext) => void,
}

export const LoadingToast = forwardRef((_, ref: Ref<LoadingToastRef>) => {
  const [ state, send ] = useMachine(loadingToastMachine);

  const { context } = state;
  useImperativeHandle(ref, () => ({
    load: () => send('LOAD'),
    error: props => send('ERROR', { ...props, isError: true }),
    success: props => send('SUCCESS', { ...props, isError: false }),
  }), [ send ]);

  function onActionClick () {
    context.action?.onClick();
    send('CLICK_ACTION');
  }

  if (state.matches('Idle')) {
    return null;
  }

  return (
    <OutsideClickHandler onClickOutside={() => send('CLOSE')}>
      <div className={styles.toast}>
        <div className={styles.outer}>
          <div
            className={csx(
              styles.inner,
              context.isError && styles.error,
              state.matches('Loading') && styles.load,
              state.matches('Animating in') && styles.come,
              state.matches('Animating out') && styles.go,
              (state.matches('Toast') || state.matches('Frozen Toast') || state.matches('Action Clicked')) && styles.stay,
            )}
            onMouseEnter={() => send('MOUSE_ENTER')}
            onMouseLeave={() => send('MOUSE_LEAVE')}
            data-testid="toast-wrapper"
            >
            <div className={styles.message}>
              <span>{context.message}</span>
              {context.action &&
                <button className={styles.action} type="button" onClick={onActionClick}>{context.action.label}</button>}
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
});
