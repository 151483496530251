import { AddCollaboratorsReqBody, AnyCollaborator, CollaboratorAccess, Collaborators, DomainAccess, EmailAccess, GroupAccess, RemoveCollaboratorsReqBody, UserAccess } from '@/api/collaborators';

export const flattenCollaborators = (
  collaborators: Collaborators,
): AnyCollaborator[] => {
  const users: AnyCollaborator[] = [];

  if (collaborators.email_domain_access?.access) {
    const emailDomain = collaborators.email_domain_access;
    users.push({
      access: emailDomain.access,
      type: 'emailDomain',
      id: emailDomain.domain,
      domain: emailDomain.domain,
    });
  }

  collaborators.group_access.forEach(({ group, access }) => {
    users.push({
      access,
      type: 'group',
      ...group,
    });
  });
  collaborators.user_access.forEach(({ user, access }) => {
    users.push({
      access,
      type: 'user',
      ...user,
    });
  });
  collaborators.email_access.forEach(({ email, access }) => {
    users.push({
      access,
      type: 'email',
      id: email,
      email,
    });
  });

  const keys = users.map(user => user.id);
  return users.filter(
    ({ id }, index) => !keys.includes(
      id,
      index + 1,
    ),
  );
};

export const mergeCollaborators = (collaborators: AnyCollaborator[]): Collaborators => {
  const users: UserAccess[] = [];
  const groups: GroupAccess[] = [];
  const emails: EmailAccess[] = [];
  const email_domain_access: DomainAccess | undefined = undefined;
  collaborators.forEach(({ access, type, ...rest }) => {
    if (type === 'user') {
      users.push({ user: rest as UserAccess['user'], access });
    }
    else if (type === 'group') {
      groups.push({ group: rest as GroupAccess['group'], access });
    }
    else if (type === 'email') {
      emails.push({ email: rest.id, access });
    }
  });

  return { user_access: users, group_access: groups, email_access: emails, email_domain_access };
};

export const removeCollaborator = (collaborators: AnyCollaborator[], collaboratorToRemove: AnyCollaborator) => {
  return collaborators.filter(collaborator => collaborator.id !== collaboratorToRemove.id);
};

export const buildRemoveCollaboratorsRequestBody = (
  { type, id }: Pick<AnyCollaborator, 'type' | 'id'>,
): RemoveCollaboratorsReqBody => {
  const req = {
    email: [] as string[],
    emailDomain: [] as string[],
    group: [] as string[],
    user: [] as string[],
  };
  req[type].push(id);

  return {
    emails: req.email,
    group_ids: req.group,
    user_ids: req.user,
    email_domain: req.emailDomain.length > 0 ? req.emailDomain.join('') : undefined,
  };
};

export const buildUpdateCollaboratorsRequestBody = (
  { type, id }: Pick<AnyCollaborator, 'type' | 'id'>,
  newAccess: CollaboratorAccess,
): AddCollaboratorsReqBody => ({
  email_access: type === 'email' ? [ { email: id, access: newAccess } ] : [],
  group_access: type === 'group' ? [ { group_id: id, access: newAccess } ] : [],
  user_access: type === 'user' ? [ { user_id: id, access: newAccess } ] : [],
  email_domain_access: type === 'emailDomain' ? { domain: id, access: newAccess } : undefined,
});
