import { useState } from 'react';

import { isValidDomain } from '@grid-is/browser-utils';
import { Icon } from '@grid-is/icon';

import { updateDomainList } from '@/api/document';
import { Button } from '@/grid-ui/Button';
import { FieldWrapper } from '@/grid-ui/FieldWrapper';
import { Input } from '@/grid-ui/Input';
import { Pill } from '@/grid-ui/Pill';
import { Toast } from '@/grid-ui/Toast';

import styles from './domainAccess.module.scss';

type DomainAccessProps = {
  documentId: string,
  allowedDomains: string[],
  onChangeDomainAccess: (domainList: string[]) => void,
};

export const DomainAccess = ({ documentId, allowedDomains = [], onChangeDomainAccess = () => {} }: DomainAccessProps) => {
  const [ input, setInput ] = useState<string>('');
  const [ inputError, setInputError ] = useState<string>('');
  const [ domains, setDomains ] = useState<string[]>(allowedDomains);
  const [ toast, setToast ] = useState<string>('');

  const addDomain = async e => {
    e.preventDefault();
    setInputError('');
    if (isValidDomain(input)) {
      // Strip out any protocol and sub pages from the domain.
      const cleanDomain = input
        .replace('https://', '')
        .replace('http://', '')
        .split('/')[0]
        .toLowerCase();
      const currentDomains = domains || allowedDomains;
      // Make sure the domain is not already in the list.
      if (!currentDomains.includes(cleanDomain)) {
        const domainList = [ ...currentDomains, cleanDomain ];
        setDomains(domainList);
        setInput('');
        await updateDomainList(documentId, domainList);
        setToast('Domain added');
        onChangeDomainAccess(domainList);
      }
      else {
        setInputError('Domain already exists.');
      }
    }
    else {
      setInputError('Please provide a valid domain.');
    }
  };

  const removeDomain = async indexToRemove => {
    const domainList = (domains || allowedDomains);
    domainList.splice(indexToRemove, 1);
    setDomains(domainList);
    await updateDomainList(documentId, domainList);
    setToast('Domain removed');
    onChangeDomainAccess(domainList);
  };

  return (
    <div className={styles.domainAccess}>
      <div className={styles.title}><Icon name="domain-lock" size={16} className={styles.domainLockIcon} />Limit domain access</div>
      <p className={styles.subTitle}>
        The embed will only be viewable on the following domains (add allowed domains below):
      </p>
      <form className={styles.domainForm} onSubmit={addDomain}>
        <FieldWrapper className={styles.input} helperText={inputError} hasError={inputError !== ''}>
          <Input name="input" placeholder="Type domain url" value={input} onChange={value => setInput(value)} hasError={inputError !== ''} required autoComplete="off" autoCorrect="off" autoCapitalize="off" />
        </FieldWrapper>
        <Button
          type="submit"
          disabled={input.length === 0}
          >
          Add domain
        </Button>
      </form>
      <div className={styles.allowedDomains}>
        {(domains || allowedDomains).map((domain, index) => (
          <Pill key={domain} text={domain} onDismiss={() => removeDomain(index)} className={styles.pill} />
        ))}
      </div>
      {toast && <Toast duration={2000} message={toast} open onClose={() => setToast('')} />}
    </div>
  );
};
