import { useCallback, useContext, useRef } from 'react';
import { SelectInstance } from 'react-select';
import { useSelector } from '@xstate/react';

import { UserType } from '@/api/user';
import { SelectOptionType } from '@/grid-ui/Select/types';
import { People, PeoplePicker } from '@/components/PeoplePicker/PeoplePicker';

import { getValuesFromContext } from './machine/selectors';
import { SharingStateContext } from './machine/SharingStateContext';

type CollaboratorsPickerProps = {
  isDocumentOwner?: boolean,
  hideButton?: boolean,
  onAdd?: () => void,
}

function userTypeGuard (obj: any): obj is UserType {
  return !!obj.username;
}

export function CollaboratorsPicker ({ isDocumentOwner, hideButton, onAdd = () => {} }: CollaboratorsPickerProps) {
  const { service } = useContext(SharingStateContext);
  const { send } = service;
  const {
    newGroups,
    newUsers,
    newInvites,
    newEmailDomain,
    collaborators,
    emailDomain,
    isOpenedFromHome,
  } = useSelector(service, getValuesFromContext('newGroups', 'newUsers', 'newInvites', 'newEmailDomain', 'collaborators', 'emailDomain', 'isOpenedFromHome'));
  const selectRef = useRef<SelectInstance<SelectOptionType>>(null);
  function onChange ({ newUsers, newInvites, newGroups, newEmailDomain }: People & { newEmailDomain?: string }) {
    send('ADD_COLLABORATOR', { newUsers, newInvites, newGroups, newEmailDomain });
    if (!newUsers.length && !newInvites.length && !newGroups.length && !newEmailDomain) {
      send('FORCE_GO_BACK');
    }
  }

  const filterOption = useCallback(
    option => {
      if (typeof option === 'string') {
        return collaborators?.find(c => c.type === 'email' && option === c.email) === undefined;
      }
      else if (userTypeGuard(option)) {
        return collaborators?.find(c => c.type === 'user' && c.id === option.id) === undefined;
      }
      return collaborators?.find(c => c.type === 'group' && c.id === option.id) === undefined;
    },
    [ collaborators ],
  );

  return (
    <PeoplePicker
      buttonLabel="Invite"
      filterOption={filterOption}
      onAdd={onAdd}
      onChange={onChange}
      canAddEmails
      canAddGroups
      canAddEmailDomain={isDocumentOwner}
      emailDomain={emailDomain.domain}
      noOptionsMessage="Search for groups, names or emails."
      placeholder="Invite by name or email "
      useModal={isOpenedFromHome}
      hideButton={hideButton}
      initialValue={{ newUsers, newInvites, newGroups, newEmailDomain }}
      ref={selectRef}
      />
  );
}
