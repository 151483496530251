import { ReactNode } from 'react';
import csx from 'classnames';

import styles from './RadioButton.module.scss';

type RadioEvent = {
  [name: string]: string,
}

export type RadioButtonProps = {
  name: string,
  id?: string,
  onChange?: (eventObject: RadioEvent) => void,
  onBlur?: (eventObject: RadioEvent) => void,
  checked?: boolean,
  disabled?: boolean,
  label?: string | ReactNode,
  size?: 'small' | 'large',
  value?: string | number,
}

export function RadioButton ({
  name,
  value,
  id = name,
  onChange = () => {},
  onBlur = () => {},
  checked,
  disabled,
  label,
  size = 'small',
}: RadioButtonProps) {
  return (
    <div className={styles.wrapper}>
      <input
        onChange={e => onChange({ [name]: e.target.value })}
        onBlur={e => onBlur({ [name]: e.target.value })}
        name={name}
        type="radio"
        value={value}
        checked={checked}
        disabled={disabled}
        id={id}
        className={csx(
          styles.input,
          size && styles[size],
        )}
        />
      <label htmlFor={id} className={csx(styles.label, size && styles[size])}>{label}</label>
    </div>
  );
}
