import { useContext, useRef } from 'react';

import { sendEmailVerification } from '@/api/user';
import { LoadingToast } from '@/grid-ui/LoadingToast';
import { LoadingToastRef } from '@/grid-ui/LoadingToast/LoadingToast';
import { TextLink } from '@/grid-ui/TextLink';

import { SharingStateContext } from './machine/SharingStateContext';

import styles from './EmailVerification.module.scss';

export function EmailVerification () {
  const loadingToast = useRef<LoadingToastRef>(null);
  const { service } = useContext(SharingStateContext);
  const userEmail = service.getSnapshot().context.userEmail;

  return (
    <>
      <div className={styles.wrapper}>
        <span className={styles.heading}>Verify your email</span>
        <div className={styles.body}>
          <p>To share your doc, please verify your email by clicking the link we sent to <strong>{userEmail}</strong></p>
          <p>Not seeing the link?</p>
          <span>Check your junk folder or {' '}
            <TextLink
              href=""
              onClick={e => {
                e.preventDefault();
                loadingToast.current?.load();
                sendEmailVerification().then(res => {
                  if (res.verification_email_was_sent) {
                    loadingToast.current?.success({ message: 'Email verification link sent.' });
                  }
                  else {
                    loadingToast.current?.error({ message: <span>Something went wrong, please contact <a href="mailto:support@calculatorstudio.co">support</a></span> });
                  }
                });
              }}
              >
              resend link
            </TextLink>
          </span>
        </div>
      </div>
      <div className={styles.loadingWrapper}>
        <LoadingToast ref={loadingToast} />
      </div>
    </>
  );
}
