import { useContext, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';

import { IconTypes } from '@grid-is/icon';
import { tracking } from '@grid-is/tracking';

import { CollaboratorAccess } from '@/api/collaborators';
import { Button } from '@/grid-ui/Button';
import { ConfirmationModal } from '@/grid-ui/Confirmation';
import { SelectInline } from '@/grid-ui/SelectInline';
import { TextLink } from '@/grid-ui/TextLink';

import { CollaboratorsPicker } from './CollaboratorsPicker';
import { canUseCollaborativeEditing, getFromContext, getInvitesLength, isInState, showCreateGroupHint } from './machine/selectors';
import { SharingStateContext } from './machine/SharingStateContext';
import { SharingHeader } from './SharingHeader';

import styles from './InvitingCollaborators.module.scss';

export function InvitingCollaborators () {
  const { service } = useContext(SharingStateContext);
  const [ access, setAccess ] = useState<CollaboratorAccess>('view');
  const { send } = service;
  const isConfirmingLeave = useSelector(service, isInState('Invite people.Confirm leave adding collaborators'));
  const canCreateGroup = useSelector(service, showCreateGroupHint);
  const documentId = useSelector(service, getFromContext('documentId'));
  const canGiveEditingRights = useSelector(service, canUseCollaborativeEditing);
  const invitesLength = useSelector(service, getInvitesLength);

  function changeAccess (selected) {
    setAccess(selected.value);
  }

  useEffect(() => {
    if (canCreateGroup) {
      tracking.logEvent('User Group Hint Shown', { document_id: documentId });
    }
  }, [ canCreateGroup, documentId ]);

  return (
    <div className={styles.container}>
      <SharingHeader heading="Share with people and groups" canGoBack />
      <div className={styles.picker}>
        <div className={styles.select}>
          <CollaboratorsPicker hideButton onAdd={() => send('SEND_INVITES', { access })} />
        </div>
        <div className={styles.rights}>
          <SelectInline
            value={access}
            onChange={changeAccess}
            items={[
              {
                label: 'View',
                value: 'view',
                icon: 'eye' as IconTypes,
              },
              {
                label: 'Edit',
                value: 'edit',
                icon: 'pencil' as IconTypes,
                hasProPill: !canGiveEditingRights,
                href: !canGiveEditingRights ? '/settings/billing' : undefined,
              },
            ]}
            />
        </div>
      </div>
      {canCreateGroup && (
        <div className={styles.createAGroupHint}>
          <TextLink linkSize="small" onClick={() => send('CREATE_GROUP')}>Create a group</TextLink> for quicker access?
        </div>
      )}
      <div className={styles.button}>
        <Button
          buttonType="primary"
          onClick={() => send('SEND_INVITES', { access })}
          >
          Send {invitesLength === 1 ? 'invite' : 'invites'}
        </Button>
      </div>
      {isConfirmingLeave && (
        <ConfirmationModal
          title="Discard invites?"
          message="Are you sure you want to discard your invites?"
          onConfirm={() => send('CONFIRM')}
          onCancel={() => {
            send('CANCEL');
          }}
          />
      )}
    </div>
  );
}
