import { useContext } from 'react';
import { useSelector } from '@xstate/react';

import { Icon } from '@grid-is/icon';

import { PopOver } from '@/grid-ui/PopOver';
import { isMobile } from '@/utils';

import { BillingContext } from '../BillingBoundary/BillingContext';
import { EmbedModal } from './EmbedModal';
import { getPropsForSharingPopover } from './machine/selectors';
import { SharingStateContext } from './machine/SharingStateContext';
import { SharingPopoverContents } from './SharingPopoverContents';

import styles from './sharing.module.scss';

export function SharingPopover ({ isDocumentOwner }: { isDocumentOwner?: boolean }) {
  const { service } = useContext(SharingStateContext);
  const billing = useContext(BillingContext);
  const { send } = service;
  const { isOpen, useModal, isInEmbedModal, isReady } = useSelector(service, getPropsForSharingPopover);
  if (isInEmbedModal) {
    return (
      <>
        {!useModal && (
          <button
            id="share-button"
            type="button"
            className={styles.shareMenuButton}
            onClick={() => {
              billing.ensureCanUseFeature('sharing', () => send('OPEN'), 'In document share button');
            }}
            data-testid="share-button"
            data-obid="share-button"
            >Share
          </button>
        )}
        <EmbedModal />
      </>
    );
  }
  return (
    <PopOver
      content={isOpen ? <SharingPopoverContents isDocumentOwner={isDocumentOwner} /> : null}
      isOpen={isOpen}
      name="Share"
      onClose={() => send('CLOSE')}
      positionRight
      animateHeight
      contentWidth={416}
      useModal={useModal}
      modalProps={{
        closeButton: isMobile(),
        header: isMobile() ? 'Share' : undefined,
        removePadding: true,
        size: 'small',
      }}
      >
      {!useModal && (
        <button
          type="button"
          className={styles.shareMenuButton}
          onClick={() => {
            billing.ensureCanUseFeature('sharing', () => send('OPEN'), 'In document share button');
          }}
          id="share-button"
          data-testid="share-button"
          disabled={!isReady}
          data-obid="share-button"
          >
          <Icon name="share" size={16} />
          Share
        </button>
      )}
    </PopOver>
  );
}
