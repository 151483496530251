import { useContext } from 'react';
import { useSelector } from '@xstate/react';
import csx from 'classnames';

import { Icon } from '@grid-is/icon';

import { TextLink } from '@/grid-ui/TextLink';

import { getFromContext } from '../machine/selectors';
import { SharingStateContext } from '../machine/SharingStateContext';
import { SharingHeader } from '../SharingHeader';
import { Collaborator } from './Collaborator';

import styles from '../sharingContent.module.scss';

type CollaboratorsListProps = {
  limit?: number,
}

export function CollaboratorsList ({
  limit,
}: CollaboratorsListProps) {
  const { service } = useContext(SharingStateContext);
  const { send } = service;
  const allCollaborators = useSelector(service, getFromContext('collaborators')) ?? [];

  const collaborators = limit
    ? allCollaborators.slice(0, limit)
    : allCollaborators;

  if (collaborators.length === 0) {
    return null;
  }

  return (
    <div className={csx(styles.collaborators, styles.list, limit && styles.bottomBorder)} data-testid="collaborators-list">
      {limit && <SharingHeader heading="People who have access" />}
      {collaborators.map(collaborator => {
        return (
          <Collaborator
            key={collaborator.id}
            collaborator={collaborator}
            />
        );
      })}
      {limit && allCollaborators.length > limit && (
        <TextLink
          onClick={() => send('SEE_COLLABORATORS')}
          className={styles.moreCollaborators}
          >
          +{allCollaborators.length - limit} more
          <Icon
            name="angle"
            direction="right"
            size={16}
            className={styles.angleRight}
            />
        </TextLink>
      )}
    </div>
  );
}
