import { useContext } from 'react';

import { Icon } from '@grid-is/icon';

import { AddCollaborators } from './AddCollaborators';
import { CollaboratorsList } from './Collaborators';
import { SharingStateContext } from './machine/SharingStateContext';
import { SharingOptions } from './SharingOptions';

import styles from './mainScreen.module.scss';

export function MainScreen ({ isDocumentOwner }: { isDocumentOwner?: boolean }) {
  const { service } = useContext(SharingStateContext);
  const { send } = service;

  return (
    <div className={styles.main}>
      <AddCollaborators isDocumentOwner={isDocumentOwner} />
      <CollaboratorsList limit={3} />
      <SharingOptions />
      <button type="button" onClick={() => send('MORE_OPTIONS')} className={styles.moreOptionsButton}>
        <span>More options</span>
        <Icon name="angle" direction="right" size={16} />
      </button>
    </div>
  );
}
