import { useContext, useRef, useState } from 'react';
import { useSelector } from '@xstate/react';

import { locationProps, query } from '@grid-is/browser-utils';
import { Icon } from '@grid-is/icon';

import { Button } from '@/grid-ui/Button';
import { Checkbox } from '@/grid-ui/Checkbox';
import { ClipBoard, CopyToClipboard } from '@/grid-ui/CopyToClipboard';
import { FieldWrapper } from '@/grid-ui/FieldWrapper';
import { HelperText } from '@/grid-ui/FieldWrapper/HelperText';
import { Input } from '@/grid-ui/Input';
import { Modal } from '@/grid-ui/Modal';
import { RadioButtons } from '@/grid-ui/RadioButtons';
import { ToggleButton } from '@/grid-ui/ToggleButton';
import { Tooltip } from '@/grid-ui/Tooltip';

import { DomainAccess } from './DomainAccess';
import { getValuesFromContext } from './machine/selectors';
import { SharingStateContext } from './machine/SharingStateContext';

import styles from './embedModal.module.scss';

const TRANSPARENT_TOGGLE_ID = 'transparent-embed-toggle';

type CreateEmbedCodeParams = {
  embedUrl: string,
  alignment: string,
  documentId: string,
  transparentBackground: boolean,
};

function createEmbedCode ({ embedUrl, alignment, documentId, transparentBackground }: CreateEmbedCodeParams): string {
  const queryParams: Record<string, string> = {};
  if (alignment === 'fill') {
    queryParams.width = 'full';
  }
  if (alignment === 'center') {
    queryParams.align = 'center';
  }
  if (transparentBackground) {
    queryParams.background = 'transparent';
  }
  queryParams.scale_to_fit = 'true';
  const url = query.set(embedUrl, queryParams);
  const { origin } = locationProps();

  return `<iframe src="${url}" width="100%" height="500" data-document-id="${documentId}" style="border: 0px;" referrerpolicy="strict-origin-when-cross-origin"></iframe>\n<!--The script automatically updates the iframe height based on the doc height.-->\n<script type="text/javascript" src="${origin}/static/embed/v1/script.js"></script>`;
}

export function EmbedModal () {
  const { service } = useContext(SharingStateContext);
  const { send } = service;

  const {
    documentId,
    getEmbedUrl,
    allowedDomains,
    hasAlteredState,
    onChangeDocumentAccess,
  } = useSelector(service, getValuesFromContext('documentId', 'hasAlteredState', 'getEmbedUrl', 'allowedDomains', 'onChangeDocumentAccess'));

  const embedCodeRef = useRef(null);
  const clipboard = useRef<ClipBoard>(null);
  const [ showMoreOptions, setShowMoreOptions ] = useState(allowedDomains.length !== 0);
  const [ alignment, setAlignment ] = useState('fill');
  const [ transparentBackground, setTransparentBackground ] = useState(false);
  const [ embedInCurrentState, setEmbedInCurrentState ] = useState(!hasAlteredState);

  const embedCode = createEmbedCode({ embedUrl: getEmbedUrl(!embedInCurrentState), alignment, documentId, transparentBackground });

  function onClose () {
    send('CLOSE');
  }

  function onChangeDomainAccess (newDomains: string []) {
    send('CHANGE_ALLOWED_DOMAINS', { newDomains });
    onChangeDocumentAccess({
      allowedDomains: newDomains,
    });
  }

  const embedUrl = getEmbedUrl(!embedInCurrentState);

  return (
    <Modal open header="Embed Options" size="medium" closeButton onClose={onClose}>
      <section className={styles.embedCode}>
        <div className={styles.controls}>
          <div className={styles.checkboxes}>
            <div className={styles.radios}>
              <label>
                <span>Alignment</span>
                <Tooltip label="Control how the doc appears inside the embedded iframe on your website.">
                  <Icon name="info-circle" size={16} />
                </Tooltip>
              </label>
              <RadioButtons
                name="alignment"
                size="large"
                value={alignment}
                onChange={value => setAlignment(value)}
                options={[
                  { id: 'fill', value: 'fill', label: 'Fill' },
                  { id: 'left', value: 'left', label: 'Left' },
                  { id: 'center', value: 'center', label: 'Center' },
                ]}
                />
            </div>
            {hasAlteredState &&
              <div className={styles.radios}>
                <label>
                  <span>Starting scenario</span>
                  <Tooltip label="Tick the box to embed your doc with the inputs set exactly the way they are right now.">
                    <Icon name="info-circle" size={16} />
                  </Tooltip>
                </label>
                <Checkbox
                  name="originalState"
                  label="Use current inputs"
                  checked={embedInCurrentState}
                  onChange={setEmbedInCurrentState}
                  />
              </div>}
          </div>
        </div>
        <div>
          <FieldWrapper className={styles.code} label="Embed code">
            <Input
              ref={embedCodeRef}
              textarea
              rows={6}
              id="embedCode"
              name="embedCode"
              value={embedCode}
              spellCheck="false"
              />
          </FieldWrapper>
          <div className={styles.actions}>
            <HelperText>Works with most CMS tools like Wordpress and more.</HelperText>
            <CopyToClipboard
              copiedText={embedCode}
              toastText="Code copied!"
              toastIcon="tick"
              onClick={() => {}}
              >
              <Button buttonType="primary">Copy code</Button>
            </CopyToClipboard>
          </div>
        </div>
        <ClipBoard ref={clipboard} />
        <div className={styles.link}>
          <div className={styles.input}>
            <FieldWrapper label="Embed link">
              <Input name="copyLink" value={embedUrl} readOnly />
            </FieldWrapper>
            <HelperText>Use for tools like Notion, Clickup & Medium.</HelperText>
          </div>
          <CopyToClipboard
            copiedText={embedUrl}
            toastText="Link copied!"
            toastIcon="tick"
            onClick={() => {}}
            >
            <Button>Copy link</Button>
          </CopyToClipboard>
        </div>
        <div className={styles.moreOptions}>
          <button type="button" onClick={() => setShowMoreOptions(!showMoreOptions)} className={styles.toggle}>More options <Icon name="angle" direction={showMoreOptions ? 'up' : 'down'} size={16} /></button>
          {showMoreOptions &&
            <>
              <div className={styles.transparentBackground}>
                <div className={styles.transparentBackgroundButtonContainer}>
                  <div className={styles.transparentBackgroundButton}>
                    <ToggleButton
                      id={TRANSPARENT_TOGGLE_ID}
                      value={transparentBackground}
                      onChange={value => {
                        setTransparentBackground(value);
                      }}
                      size="small"
                      />
                  </div>
                  <label htmlFor={TRANSPARENT_TOGGLE_ID} className={styles.transparentBackgroundLabel}>
                    Transparent background
                  </label>
                </div>
                <span className={styles.transparentBackgroundSubtitle}>
                  The background on your website will show through the embed.
                </span>
              </div>
              <DomainAccess
                onChangeDomainAccess={onChangeDomainAccess}
                documentId={documentId}
                allowedDomains={allowedDomains}
                />
            </>
            }
        </div>
      </section>
    </Modal>
  );
}
