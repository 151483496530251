import { CellCSF } from '@grid-is/apiary/lib/csf';

import { chartBuilder } from './chartBuilder';
import { savingsCalculator } from './savingsCalculator';
import { simpleCharts } from './simpleCharts';

type Dialog = {
  title: string,
  body?: string | JSX.Element,
  gif?: string,
  placement?: 'top' | 'bottom' | 'left' | 'right' | 'center' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
}

type Instruction = Dialog & {
  completion?: 'click' | 'value' | 'add-element' | 'enter',
  completionValue?: string | string[],
  clipboardCells?: { cells: Record<string, Partial<CellCSF>>, colWidths?: Record<number, number>},
  completionElement?: string,
  completionDelay?: number,
  completionConfetti?: boolean,
  mainTarget?: string,
  otherTargets?: string[],
  lockFocus?: boolean,
  autoFocus?: boolean,
  noBlur?: boolean,
  doBefore?: () => Promise<void> | void,
  name: string, // Used for tracking only
  gif?: string,
  noActionRequired?: boolean,
  unlockUi?: boolean,
  onDoItForMe?: () => void,
  disableKeyboard?: boolean,
}

export type Tutorial = {
  instructions: Instruction[],
  previewInstructions: Instruction[],
  skipIntro?: boolean,
  skipOutro?: boolean, // To be implemented
  intro?: Dialog, // To be implemented
  outro: Dialog,
  // Used in the tutorial gallery
  title: string,
  description: string,
  thumbnail: string,
  durationMinutes: number,
}

// Add new tutorials here:
export const tutorials = {
  chart_builder: chartBuilder,
  simple_charts: simpleCharts,
  savings_calculator: savingsCalculator,
};

// Tutorials for AB tests:
export const abTestTutorials = {
};

export type TutorialsType = keyof typeof tutorials | keyof typeof abTestTutorials;
