import csx from 'classnames';

import { ViewAccess } from '@/api/document';
import { Features } from '@/api/types';
import { useDeps } from '@/bootstrapping/dependencies';

import { EnableEmbedding } from './EnableEmbedding';
import { SharingStateContextProvider } from './machine/SharingStateContext';
import { SharingPopover } from './SharingPopover';

import styles from './sharing.module.scss';

export type OnChangeAccessArgs = {
  mode?: ViewAccess,
  allowedDomains?: string[],
  isDuplicatable?: boolean,
  isEmbeddable?: boolean,
  hasPassword?: boolean,
  viewersCanSaveScenarios?: boolean,
}

export type SharingProps = {
  allowedDomains: string[],
  canEdit?: boolean,
  viewersCanSaveScenarios?: boolean,
  currentUserId?: string,
  documentId: string,
  documentOwnerId: string,
  documentTitle?: string,
  suggestedTitle?: string,
  emailDomain?: string,
  getEmbedUrl: (shareInOriginalState?: boolean) => string,
  hasAlteredState: boolean,
  isDocDuplicable?: boolean,
  isEmbeddable?: boolean,
  isDocumentOwner: boolean,
  isVerified: boolean,
  userEmail?: string,
  onChangeDocumentAccess: (newAccess: OnChangeAccessArgs) => void,
  onClose?: (documentId: string) => void,
  isOpenedFromHome?: boolean,
  statefulUrl?: string,
  viewAccess: ViewAccess,
  onTitleChange?: (documentTitle: string, documentId?: string) => void,
  enabledFeatures: Partial<Record<Features, boolean>>,
}

export function Sharing (props: SharingProps) {
  const {
    emailDomain,
    canEdit,
    ...rest
  } = props;
  const { api, userEvents } = useDeps();

  if (props.isOpenedFromHome) {
    return (
      <SharingStateContextProvider
        newUsers={[]}
        newGroups={[]}
        newInvites={[]}
        emailDomain={{ domain: emailDomain }}
        documentService={api.documents}
        userEvents={userEvents}
        canEdit={canEdit}
        {...rest}
        >
        <SharingPopover isDocumentOwner={props.isDocumentOwner} />
      </SharingStateContextProvider>
    );
  }
  return (
    <SharingStateContextProvider
      newUsers={[]}
      newGroups={[]}
      newInvites={[]}
      emailDomain={{ domain: emailDomain }}
      documentService={api.documents}
      userEvents={userEvents}
      canEdit={canEdit}
      {...rest}
      >
      <div className={styles.container} data-testid="sharing-modal">
        {
          canEdit && (
            <div className={csx(styles.shareButtonContainer, styles.secondary)}>
              <SharingPopover isDocumentOwner={props.isDocumentOwner} />
            </div>
          )
        }

      </div>
      {canEdit && <EnableEmbedding />}
    </SharingStateContextProvider>
  );
}
