import { ReactNode } from 'react';
import { assign, createMachine } from 'xstate';

export type LoadingToastContext = {
  message?: ReactNode,
  action?: {label: ReactNode, onClick: () => void, onClickMessage?: string},
  isError?: boolean,
}

type LoadingToastEvents =
{type: 'LOAD'} |
{type: 'CLICK_ACTION'} |
{type: 'SUCCESS' } & LoadingToastContext |
{type: 'ERROR' } & LoadingToastContext |
{type: 'CLOSE'} |
{type: 'MOUSE_LEAVE'} |
{type: 'MOUSE_ENTER'};

export const loadingToastMachine =
/** @xstate-layout N4IgpgJg5mDOIC5QBsD2BDCBLAdlAKhrAC4DEAogEqUDylA2gAwC6ioADqrFsVqjmxAAPRABYATABoQAT0TiAbAEYAdAHZFAVlGiAHGoCcBiQs0BfM9LSZcBImQAyNAIIARJqyQhO3Xv0EiCBLScggAzJq6KkqGCgphimGiYcYWVhjYeIToJKQAygCqAMJF5Hl5HoI+PHwCXoHBsohKpiriamFhahKKCkZKaSDWmXY5xCrOOFgAtui8eAAEuKRCJHNgKugAZsRgAE4AFGGMjACUpMO22SQTU7PzUEs4lV7VfnWggZ2iKmEKakpNDFxEpQREQvI1FEDJpjoZdGFdOJxIw1INLll7CprmQALI0Ap5cgAfXIADl8FQXhwuDV-PUxIoVJooYwDH9RJo2Y1QjE1CoYcdNOIujFRAZdOiMlcsTiVmtdpsdvsDuJRCdzhjRjccdTvLT3gFEGpNAZ1EpdApFLoWTDGGEIQgReIVJyhUpGO0Tly0ZYhtLMWNbjM5rYFqgAK5kVbEdZK3aHLlnC4B7XjSYhh7hqN6t61I0ID1c9S9Ri6RgWzSRBSO5GaNr2j0e1H6AxqqU2QM3ABie1QAC8wDgFnL8YSSQ5yM4AGrkXMG-MM8JJX7-QHA0FKcFNJ3iKJuxhxD3im3iDsjHEqXsDocj+ykIoOACSRQA0sTnEV8E+aGT575F0+RBvlXAEgQ0Tdt1CXRVHFIxLXEW0jBNc8ZSDZwAGN3gWIpkCwDCAGtIHlWNFW2BNVXVZMtUvTDsNw-CiIgf86Q+YRjVNc0EIUG1DC5B0dyLAU1AUcUOjUT0RJhCw-RwVAIDgQQaPsKoF3pICEAAWhrHdNPrIwjCtT0LRNOJUK7cYnwgZAwFUgD1PYwsLQUdQWUMcUgSBFpaxOdR7UYTRlCUEUWjCcy0xUBxUzs1iCxiFzRN0CR4O0FFYUdCIon+Aw4gSeJklSP1lPQu5Q0WXAYsNJd4pURLkolVKAoE0J2n5QVuQMFoUVRX10k7CKcUqwDHOUetTT+USlFEbpIlEWswlg2FD2URgT2FMKitTWjSqzSNiCGhzAl0YwBRBS1m0UdUlFrNUG2OeIYKCxJwsva9B2HQbXjUtjAhiVQ6vEFKJCa2sLRUFtD0UAF-gel6sTo2ocLwwjIAOn7EAUXzsviPp2S3a7BNW4TTGCnQkRZVEZLMIA */
createMachine({
  tsTypes: {} as import('./LoadingToastMachine.typegen').Typegen0,

  schema: {
    context: {} as LoadingToastContext,
    events: {} as LoadingToastEvents,
  },

  predictableActionArguments: true,

  on: {
    ERROR: {
      actions: 'setError',
      target: 'Animating in',
    },
    LOAD: {
      target: 'Loading',
    },
    SUCCESS: {
      target: 'Animating in',
      actions: 'setToast',
    },
    CLICK_ACTION: {
      target: 'Action Clicked',
      cond: 'hasAction',
      actions: 'clickAction',
    },
  },

  states: {
    'Idle': {
      entry: 'reset',
    },
    'Loading': {},
    'Animating in': {
      after: {
        300: {
          target: '#loadingToast.Toast',
          actions: [],
          internal: false,
        },
      },
    },
    'Toast': {
      after: {
        2400: {
          target: '#loadingToast.Animating out',
          actions: [],
          internal: false,
        },
      },
      on: {
        MOUSE_ENTER: {
          target: 'Frozen Toast',
        },
        CLOSE: 'Animating out',
      },
    },
    'Animating out': {
      after: {
        500: {
          target: '#loadingToast.Idle',
          actions: [],
          internal: false,
        },
      },
    },
    'Frozen Toast': {
      on: {
        MOUSE_LEAVE: {
          target: 'Toast',
        },
      },
    },
    'Action Clicked': {
      after: {
        2400: {
          target: '#loadingToast.Animating out',
          actions: [],
          internal: false,
        },
      },
    },
  },

  id: 'loadingToast',
  initial: 'Idle',
}, {
  actions: {
    setToast: assign((context, event) => {
      return { ...context, ...event, isError: false };
    }),
    setError: assign((context, event) => {
      return { ...context, ...event, isError: true };
    }),
    clickAction: assign(context => {
      return { ...context, message: context.action?.onClickMessage ?? context.message, action: undefined };
    }),
    reset: assign(context => {
      return { ...context, message: undefined, action: undefined, isError: undefined };
    }),
  },
  guards: {
    hasAction: context => context.action !== undefined,
  },
});
