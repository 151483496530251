import { useContext } from 'react';
import { useSelector } from '@xstate/react';
import csx from 'classnames';

import { Icon, IconTypes } from '@grid-is/icon';

import { Button } from '@/grid-ui/Button';
import { confirmation } from '@/grid-ui/Confirmation';
import { SelectInline } from '@/grid-ui/SelectInline';
import { Tooltip } from '@/grid-ui/Tooltip';

import { getFromContext } from './machine/selectors';
import { SharingStateContext } from './machine/SharingStateContext';

import styles from './sharingOptions.module.scss';

export const EmbedOption = () => {
  const { service } = useContext(SharingStateContext);
  const { send } = service;
  const isEmbeddable = useSelector(service, getFromContext('isEmbeddable')) || false;

  function getCode () {
    if (!isEmbeddable) {
      confirmation({
        title: 'Enable embedding?',
        message: 'To get an embed code for this project you need to enable embedding first.',
        type: 'constructive',
        confirmLabel: 'Enable embedding',
        onConfirm: () => {
          send('SET_IS_EMBEDDABLE_AND_OPEN_EMBED_MODAL');
        },
      });
    }
    else {
      send('GET_EMBED_CODE');
    }
  }

  return (
    <div className={styles.sharingOption} data-testid="embedOption">
      <Tooltip label="With embedding enabled you and anyone else can view and embed on external websites." placement="left" hideOnElementClick delay={1200} wrapElement wrapClassName={styles.tooltipWrap}>
        <>
          <Icon name="code" size={24} className={csx(styles.icon, isEmbeddable && styles.enabled)} />
          <div className={styles.wrap} data-obid="embed-option">
            <span>Embedding is</span>
            <SelectInline
              value={isEmbeddable}
              onChange={({ value }) => {
                send('SET_IS_EMBEDDABLE', { newValue: value });
              }}
              items={[
                {
                  label: 'disabled',
                  value: false,
                  icon: 'window-close' as IconTypes,
                },
                {
                  label: 'enabled',
                  value: true,
                  icon: 'globe' as IconTypes,
                },
              ]}
              />
          </div>
        </>
      </Tooltip>
      <div className={styles.buttons}>
        <Button buttonType="primary" onClick={getCode} className={styles.button}>Embed</Button>
      </div>
    </div>
  );
};
