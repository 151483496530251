import { Avatar } from '@/grid-ui/Avatar';
import { Tooltip } from '@/grid-ui/Tooltip';

import styles from './AvatarCentipede.module.scss';

export type AvatarType = {
  name?: string,
  username?: string,
  avatar_url?: string,
  isGroup?: boolean,
}

type Props = {
  avatars: AvatarType[],
  size: number,
  max?: number,
};

export const AvatarCentipede = ({ avatars, size, max }: Props) => {
  const renderedAvatars = max ? avatars.slice(0, max) : avatars;

  const numberOfHiddenAvatars = max ? Math.max((avatars.length ?? 0) - max, 0) : 0;
  return (
    <div
      className={styles.avatarCentipede}
      data-testid="avatar-centipede"
      style={{
        height: `${size}px`,
        width: `${size + (size * 1.1 * (renderedAvatars.length - 1))}px`,
      }}
      >
      {renderedAvatars.map((avatar, index) => (
        <Tooltip
          label={`${avatar.name}`}
          hideOnElementClick
          placement="bottom"
          key={avatar.username}
          >
          <Avatar
            style={{ transform: `translateX(${index * ((1 - 4 / 24) * 100)}%)`, border: `${size < 24 ? 1.5 : 2}px solid #fff` }}
            size={size}
            name={avatar.name}
            username={avatar.username}
            avatarUrl={avatar.avatar_url}
            isGroup={avatar.isGroup}
            />
        </Tooltip>
      ))}
      {numberOfHiddenAvatars > 0 && (
        <Tooltip
          label={`${numberOfHiddenAvatars} more`}
          hideOnElementClick
          placement="bottom"
          key={`hidden-${numberOfHiddenAvatars}`}
          >
          <Avatar
            style={{ transform: `translateX(${renderedAvatars.length * ((1 - 4 / 24) * 100)}%)`, border: `${size < 24 ? 1.5 : 2}px solid #fff` }}
            size={size}
            isXMore
            username={String(numberOfHiddenAvatars)}
            />
        </Tooltip>
      )}
    </div>
  );
};
