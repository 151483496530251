import { CollaboratorsPicker } from './CollaboratorsPicker';
import { SharingHeader } from './SharingHeader';

import styles from './addCollaborators.module.scss';

export function AddCollaborators ({ isDocumentOwner }: { isDocumentOwner?: boolean }) {
  return (
    <div className={styles.addCollaborators}>
      <SharingHeader heading="Share with people and groups" />
      <CollaboratorsPicker isDocumentOwner={isDocumentOwner} />
    </div>
  );
}
