import { useContext, useEffect } from 'react';
import { useSelector } from '@xstate/react';
import { useRouter } from 'next/router';

import { remove } from '@grid-is/browser-utils/src/query';

import { confirmation } from '@/grid-ui/Confirmation';
import { SharingStateContext } from '@/components/Sharing/machine/SharingStateContext';

import { BillingContext } from '../BillingBoundary/BillingContext';
import { getValuesFromContext } from './machine/selectors';

export const EnableEmbedding = () => {
  const { service } = useContext(SharingStateContext);
  const billing = useContext(BillingContext);
  const { send } = service;
  const { isEmbeddable } = useSelector(service, getValuesFromContext('isEmbeddable'));

  const router = useRouter();
  const { onPageLoad } = router.query;
  function onButtonClick () {
    if (!isEmbeddable) {
      confirmation({
        title: 'Enable embedding?',
        message: 'To generate an embed code for this project, please enable embedding first.',
        type: 'constructive',
        confirmLabel: 'Enable embedding',
        onConfirm: () => {
          billing.ensureCanUseFeature('embed', () => send('SET_IS_EMBEDDABLE_AND_OPEN_EMBED_MODAL'), 'In document embed button');
        },
      });
    }
    else {
      billing.ensureCanUseFeature('embed', () => send('GET_EMBED_CODE'), 'In document embed button');
    }
  }

  useEffect(() => {
    if (onPageLoad === 'openEmbedModal') {
      const url = remove(router.asPath, 'onPageLoad');
      router.replace(url);
      onButtonClick();
    }
    // only listen to the first time the page loads
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
