import { MouseEvent, useCallback, useContext, useState } from 'react';
import { useSelector } from '@xstate/react';
import csx from 'classnames';

import { tracking } from '@grid-is/tracking';

import { Button } from '@/grid-ui/Button';
import { Input } from '@/grid-ui/Input';

import { getFromContext } from './machine/selectors';
import { SharingStateContext } from './machine/SharingStateContext';

import styles from './setTitle.module.scss';

export const SetTitle = () => {
  const { service } = useContext(SharingStateContext);
  const { send } = service;
  const documentId = useSelector(service, getFromContext('documentId'));
  const inModal = useSelector(service, getFromContext('isOpenedFromHome'));
  const suggestedTitle = useSelector(service, getFromContext('suggestedTitle'));

  const [ title, setTitle ] = useState(suggestedTitle || '');

  const onClickSave = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (title !== 'Untitled' && title !== '') {
      tracking.logEvent('Document Title Prompt Action Taken', { document_id: documentId, initiated_from: inModal ? 'Home' : 'In Document', title_prompt_action: 'Save' });
      send('SET_TITLE', { title });
    }
    else {
      tracking.logEvent('Document Title Prompt Action Taken', { document_id: documentId, initiated_from: inModal ? 'Home' : 'In Document', title_prompt_action: 'Skip' });
    }
  }, [ inModal, title, send, documentId ]);

  const handleOnKeyDown = event => {
    if (event.key === 'Enter' && title !== '') {
      onClickSave(event);
    }
  };

  return (
    <div className={csx(styles.wrapper, inModal && styles.modalWrapper)}>
      <h2 className={styles.heading}>Add a title before sharing</h2>
      <p className={styles.paragraph}>Titles help you and your team stay organized.</p>
      <Input
        className={styles.input}
        name="documentTitle"
        type="text"
        placeholder="Untitled"
        value={title}
        onChange={e => setTitle(e)}
        onKeyDown={e => handleOnKeyDown(e)}
        testId="sharingTitleInput"
        />
      <div className={styles.loader} />
      <div className={styles.buttonWrapper}>
        <Button
          buttonType="secondary"
          onClick={() => {
            tracking.logEvent('Document Title Prompt Action Taken', { document_id: documentId, initiated_from: inModal ? 'Home' : 'Document', title_prompt_action: 'Skip' });
            send('SKIP');
          }}
          >Skip
        </Button>
        <Button buttonType="primary" onClick={onClickSave}>Save</Button>
      </div>
    </div>
  );
};
