import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import { useSelector } from '@xstate/react';
import csx from 'classnames';

import { LoadingToast } from '@/grid-ui/LoadingToast';
import { LoadingToastRef } from '@/grid-ui/LoadingToast/LoadingToast';

import { AllCollaborators } from './AllCollaborators';
import { CreateGroup } from './CreateGroup';
import { EmailVerification } from './EmailVerification';
import { InvitingCollaborators } from './InvitingCollaborators';
import { canGoBack, getCurrentState, getFromContext, isLoading } from './machine/selectors';
import { SharingStateContext } from './machine/SharingStateContext';
import { MainScreen } from './MainScreen';
import { MoreOptions } from './MoreOptions';
import { SetTitle } from './SetTitle';

import styles from './sharingPopoverContents.module.scss';

export function SharingPopoverContents ({ isDocumentOwner }: { isDocumentOwner?: boolean }) {
  const { service } = useContext(SharingStateContext);
  const { send } = service;
  // Selectors
  const state = useSelector(service, getCurrentState);
  const showSecondarySection = useSelector(service, canGoBack);
  const loading = useSelector(service, isLoading);
  const renderToast = useSelector(service, getFromContext('renderToast'));

  // Refs
  const loadingRef = useRef<LoadingToastRef>(null);
  const [ primarySectionRef, { height: primarySectionHeight } ] = useMeasure<HTMLDivElement>();
  const [ secondarySectionRef, { height: secondarySectionHeight } ] = useMeasure<HTMLDivElement>();

  // State
  const [ showPrevState, setShowPrevState ] = useState(false);

  useEffect(() => {
    if (loading) {
      loadingRef.current?.load();
    }
  }, [ loading ]);

  useEffect(() => {
    if (renderToast) {
      const toast = renderToast(send);
      if (toast.isError) {
        loadingRef.current?.error(toast);
      }
      else {
        loadingRef.current?.success(toast);
      }
    }
  }, [ renderToast, send ]);

  useEffect(() => {
    setShowPrevState(true);
    const timeout = setTimeout(() => {
      setShowPrevState(false);
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [ showSecondarySection ]);

  const showState = useCallback(name => {
    // a state component should be shown if it is the current state, or we are animating from this specific state
    return (state.matches(name) || (state.history?.matches(name) && showPrevState));
  }, [ state, showPrevState ]);

  const menuHeight = showSecondarySection ? secondarySectionHeight : primarySectionHeight;

  return (
    <>
      <div className={styles.sharing} style={{ height: menuHeight ? `${menuHeight}px` : 'initial' }} data-testid="sharing">
        <div className={csx(styles.sections, showSecondarySection ? styles.showSecondary : styles.showPrimary)}>
          <div className={styles.section}>
            <div className={styles.primary} ref={primarySectionRef}>
              {showState('Main') && <MainScreen isDocumentOwner={isDocumentOwner} />}
              {showState('Email verification') && <EmailVerification />}
              {showState('Set title') && <SetTitle />}
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.secondary} ref={secondarySectionRef}>
              {showState('More options') && <MoreOptions />}
              {showState('List of collaborators') && <AllCollaborators />}
              {showState('Invite people') && <InvitingCollaborators />}
              {showState('Create group') && <CreateGroup />}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sharingToast}>
        <LoadingToast ref={loadingRef} />
      </div>
    </>
  );
}
