import { cloneElement, FC, ReactElement, RefObject, useRef, useState } from 'react';

import { IconTypes } from '@grid-is/icon';

import { Toast } from '@/grid-ui/Toast';

import { ClipBoard } from './ClipBoard';

type CopyToClipboardProps ={
  toastText: string,
  copiedText?: string,
  toastIcon?: IconTypes,
  onClick: () => void,
  disabled?: boolean,
  children: React.ReactNode,
}

function mergefunction (clipboard: RefObject<ClipBoard>, setOpenToast: (boolean) => void, onClick: () => void, copiedText?: string) {
  clipboard.current?.copy(copiedText);
  onClick();
  copiedText && setOpenToast(true);
}

//
export const CopyToClipboard: FC<CopyToClipboardProps> = ({ copiedText, toastText, toastIcon, onClick, disabled, children }) => {
  const clipboard = useRef<ClipBoard>(null);
  const [ openToast, setOpenToast ] = useState(false);

  return (
    <>
      <ClipBoard ref={clipboard} />
      {cloneElement(
        children as ReactElement,
        { onClick: () => mergefunction(clipboard, setOpenToast, onClick, copiedText), disabled: disabled },
      )}
      <Toast
        open={openToast}
        duration={2000}
        onClose={() => setOpenToast(false)}
        icon={toastIcon}
        onClick={() => setOpenToast(false)}
        message={<span>{toastText}</span>}
        />
    </>
  );
};
