import { PureComponent } from 'react';
// only keep one global helper around
let elm: null | HTMLTextAreaElement = null;

export class ClipBoard extends PureComponent {
  componentDidMount () {
    if (!elm) {
      elm = document.createElement('textarea');
      elm.setAttribute('tabIndex', '-1');
      elm.setAttribute('aria-hidden', 'true');
      elm.setAttribute('readOnly', 'true');
      elm.value = '';
      const style = elm.style;
      style.position = 'fixed';
      style.top = '-100rem';
      style.left = '-100rem';
      style.opacity = '0';
      style.height = '1px';
      style.width = '1px';
      document.body.appendChild(elm);
    }
  }

  copy (text) {
    if (elm && text) {
      elm.value = String(text);
      elm.select();
      document.execCommand('copy');
    }
  }

  render () {
    return null;
  }
}
