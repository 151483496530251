import { Tutorial } from '.';

const cells = {
  A1: { v: 'Sales forecast' },
  A3: { v: 'Year' },
  B3: { v: 'Berries \ud83e\uded0' },
  C3: { v: 'Oranges \ud83c\udf4a' },
  E3: { v: 'Yearly growth' },
  A4: { z: '0', v: 2022 },
  B4: { z: '0', v: 1250 },
  C4: { z: '0', v: 500 },
  E4: { z: '0%', v: 0.25 },
  A5: { z: '0', v: 2023 },
  B5: { z: '0', v: 1562.5, f: 'B4*(100% + $E$4)' },
  C5: { z: '0', v: 625, f: 'C4*(100% + $E$4)' },
  A6: { z: '0', v: 2024 },
  B6: { z: '0', v: 1953.125, f: 'B5*(100% + $E$4)' },
  C6: { z: '0', v: 781.25, f: 'C5*(100% + $E$4)' },
  A7: { z: '0', v: 2025 },
  B7: { z: '0', v: 2441.40625, f: 'B6*(100% + $E$4)' },
  C7: { z: '0', v: 976.5625, f: 'C6*(100% + $E$4)' },
  A8: { z: '0', v: 2026 },
  B8: { z: '0', v: 3051.7578125, f: 'B7*(100% + $E$4)' },
  C8: { z: '0', v: 1220.703125, f: 'C7*(100% + $E$4)' },
};

// Temporary tutorial change until we have
// added support for project sheets in the tutorial
export const simpleCharts: Tutorial = {
  title: 'Simple charts',
  description: 'Build charts from your spreadsheet data.',
  durationMinutes: 4,
  thumbnail: '/img/ob-tutorial-thumbnail-simple-charts.png',
  outro: {
    title: 'You rock 🤘',
    body: (<>Now that you've got the basics down, keep exploring Calculator Studio and see what you can do with data 😄</>),
    placement: 'center',
  },
  previewInstructions: [],
  instructions: [
    {
      name: 'Start a project sheet',
      completion: 'click',
      mainTarget: 'start-from-scratch',
      title: 'Create a spreadsheet',
      body: (<>Click <strong>Start from scratch</strong> to add data to your project.</>),
      placement: 'bottom',
    },
    {
      name: 'Paste data',
      completion: 'click',
      completionDelay: 1000,
      mainTarget: 'paste-data-button',
      clipboardCells: { cells },
      title: 'Now all you need is data!',
      body: (<>We got this one, just click <strong>Give me data</strong> and we'll paste in some data for you.</>),
      placement: 'center',
    },
    {
      name: 'Add column chart',
      completion: 'add-element',
      completionElement: 'column',
      mainTarget: 'Column chart',
      otherTargets: [ 'accordion-Charts', 'action-button-elements', 'empty-placeholder-add-element', 'element-option-Column chart'  ],
      title: 'Add a column chart',
      body: (<>Great! 💪 Now, use the Element menu to add a <strong>Column chart</strong> to your project.</>),
      placement: 'center',
    },
    {
      name: 'Connect column chart',
      completion: 'value',
      completionDelay: 1000,
      completionValue: [ '=B4:C8', '=Sheet1!B4:C8', "='[Project sheet]'Sheet1!B4:C8" ],
      mainTarget: 'FORMULA-expr',
      otherTargets: [ 'sheet-content' ],
      lockFocus: true,
      autoFocus: true,
      title: 'Connect data to your chart',
      body: (<>With the <strong>Data</strong> field selected, click and drag to select the range <var>B4:C8</var> in the spreadsheet.</>),
      gif: '/img/ob-chart-data-v2.gif',
      placement: 'left',
    },
    {
      name: 'Submit your selection',
      completion: 'click',
      mainTarget: 'element-hero-continue-button',
      title: 'Submit your selection',
      body: (<>Click <strong>Continue</strong> to submit your data selection.</>),
      placement: 'top-right',
    },
    {
      name: 'Add slider',
      completion: 'add-element',
      completionElement: 'slider',
      mainTarget: 'Slider',
      otherTargets: [ 'action-button-elements', 'empty-placeholder-add-element', 'element-option-Slider' ],
      title: 'Add a slider',
      body: (<>That's a good looking chart! Now let's add some interactivity. Find the <strong>Slider</strong> in the Element menu.</>),
      placement: 'center',
    },
    {
      name: 'Connect slider',
      completion: 'value',
      completionDelay: 1000,
      completionValue: [ '=E4', '=Sheet1!E4', "='[Project sheet]Sheet1'!E4" ],
      mainTarget: 'FORMULA-expr',
      otherTargets: [ 'sheet-content', 'target-cell-suggestions' ],
      lockFocus: true,
      autoFocus: true,
      title: 'Connect your slider',
      body: (<>With <strong>Target cell</strong> selected, choose <var>E4</var> as the data reference for your slider.</>),
      gif: '/img/ob-slider-data-v2.gif',
      placement: 'left',
    },
    {
      name: 'Submit your selection',
      completion: 'click',
      mainTarget: 'element-hero-continue-button',
      title: 'Submit your selection',
      body: (<>Click <strong>Continue</strong> to submit your data selection.</>),
      placement: 'top-right',
    },
    {
      name: 'Interact with slider',
      completion: 'click',
      mainTarget: 'grid-slider-track',
      title: 'Try moving your slider',
      body: 'Ready for some magic? See your model update instantly when you interact with the slider.',
      gif: '/img/ob-slider-move-v2.gif',
      placement: 'bottom-right',
      completionConfetti: true,
    },
    {
      name: 'Continue to part 2',
      completion: 'click',
      mainTarget: 'ob-continue',
      title: 'Great work 🥳',
      body: 'Your project is now interactive. To finish up, let\'s quickly add some final touches.',
      placement: 'center',
      noActionRequired: true,
    },
    {
      name: 'Slider label',
      completion: 'value',
      completionDelay: 1000,
      completionValue: [ '=E3', '=Sheet1!E3', 'Yearly growth', "='[Project sheet]'Sheet1!E3" ],
      mainTarget: 'FORMULA-title',
      otherTargets: [ 'sheet-content' ],
      autoFocus: true,
      lockFocus: true,
      title: 'Add a label to your slider',
      body: (<>With <strong>Label</strong> selected, choose <var>E3</var> as the reference, or type in <strong>Yearly growth</strong>.</>),
      placement: 'top',
    },
    {
      name: 'Select column chart',
      completion: 'click',
      mainTarget: 'grid-column',
      title: 'Select the column chart',
      body: (<>Click the column chart 👆</>),
      placement: 'bottom',
    },
    {
      name: 'Chart title',
      completion: 'value',
      completionDelay: 1000,
      completionValue: [ '=A1', '=Sheet1!A1', 'Sales forecast', "='[Project sheet]'Sheet1!A1" ],
      mainTarget: 'FORMULA-title',
      otherTargets: [ 'sheet-content' ],
      autoFocus: true,
      lockFocus: true,
      title: 'Give your chart a title',
      body: (<>With <strong>Title</strong> selected, choose <var>A1</var> as the reference, or type in <strong>Sales forecast</strong>.</>),
      placement: 'top',
    },
    {
      name: 'Chart legend',
      completion: 'value',
      completionDelay: 1000,
      completionValue: [ '=B3:C3', '=Sheet1!B3:C3', "='[Project sheet]'Sheet1!B3:C3" ],
      mainTarget: 'FORMULA-legend',
      otherTargets: [ 'sheet-content' ],
      autoFocus: true,
      lockFocus: true,
      title: 'Add a legend',
      body: (<>With <strong>Legend</strong> selected, choose <var>B3:C3</var> as the reference.</>),
      placement: 'top',
    },
    {
      name: 'Chart axes',
      completion: 'value',
      completionDelay: 1000,
      completionValue: [ '=A4:A8', '=Sheet1!A4:A8', "='[Project sheet]'Sheet1!A4:A8" ],
      mainTarget: 'FORMULA-labels',
      otherTargets: [ 'sheet-content' ],
      autoFocus: true,
      lockFocus: true,
      title: 'Add labels to your chart',
      body: (<>With <strong>X-axis labels</strong> selected, choose <var>A4:A8</var> as the reference.</>),
      placement: 'top',
    },
    {
      name: 'Document title',
      completion: 'value',
      completionDelay: 1000,
      completionValue: [ 'Sales forecast' ],
      mainTarget: 'doc-title',
      autoFocus: true,
      lockFocus: true,
      title: 'Change your project title',
      body: (<>Rename your project to <strong>Sales forecast</strong>.</>),
      placement: 'left',
    },
    {
      name: 'Preview',
      completion: 'click',
      mainTarget: 'edit-toggle',
      title: 'Ready to preview?',
      body: (<>Click <strong>View</strong> and see how your finished project will look to viewers.</>),
      placement: 'center',
    },
    {
      name: 'Play around',
      completion: 'click',
      mainTarget: 'ob-continue',
      title: 'Nice work 👏',
      body: (<>Try interacting with your project and then click <strong>Continue</strong> when you are done.</>),
      placement: 'bottom',
      noActionRequired: true,
    },
  ],
};
