import { useCallback, useContext } from 'react';
import { useSelector } from '@xstate/react';

import { IconTypes } from '@grid-is/icon';

import { AnyCollaborator, CollaboratorAccess } from '@/api/collaborators';
import { SelectInline, SelectInlineListItem } from '@/grid-ui/SelectInline';

import { canUseCollaborativeEditing } from '../machine/selectors';
import { SharingStateContext } from '../machine/SharingStateContext';

type CollaboratorAccessProps = {
  collaborator: AnyCollaborator,
  currentAccess: CollaboratorAccess,
}

export const CollaboratorAccessControl: React.VFC<CollaboratorAccessProps> = ({ collaborator: c, currentAccess }) => {
  const { service } = useContext(SharingStateContext);
  const { send } = service;
  const canGiveEditingRights = useSelector(service, canUseCollaborativeEditing);

  const accessOptions: SelectInlineListItem[] = [
    {
      label: 'View',
      value: 'view',
      icon: 'eye' as IconTypes,
    },
    {
      label: 'Edit',
      value: 'edit',
      icon: 'pencil' as IconTypes,
      hasProPill: !canGiveEditingRights,
      href: !canGiveEditingRights ? '/settings/billing' : undefined,
    },
    {
      label: 'Remove',
      value: 'remove',
      icon: 'window-close' as IconTypes,
      hasDividerOnTop: true,
    },
  ];

  const onUpdateAccess = useCallback((newAccess: CollaboratorAccess | 'remove') => {
    if (newAccess === 'remove') {
      send('REMOVE_COLLABORATOR', { collaboratorToRemove: c });
    }
    else {
      send('SET_COLLABORATOR_ACCESS', { collaboratorToUpdate: c, newAccess });
    }
  }, [ c, send ]);

  return (
    <SelectInline value={currentAccess} items={accessOptions} onChange={selected => onUpdateAccess(selected.value as CollaboratorAccess)} />
  );
};
