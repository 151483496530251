import { useContext } from 'react';

import { Icon } from '@grid-is/icon';

import { CollaboratorsList } from './Collaborators';
import { SharingStateContext } from './machine/SharingStateContext';
import { SharingHeader } from './SharingHeader';

import styles from './sharingContent.module.scss';

export function AllCollaborators () {
  const { service } = useContext(SharingStateContext);
  const { send } = service;

  return (
    <div className={styles.collaborators}>
      <SharingHeader heading="People who have access" canGoBack />
      <CollaboratorsList />
      <button type="button" className={styles.backToSharingButton} onClick={() => send('GO_BACK')}>
        <Icon name="angle" direction="left" size={16} className={styles.icon} />
        <span>Back to sharing</span>
      </button>
    </div>
  );
}
