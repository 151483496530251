import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import csx from 'classnames';
import Link from 'next/link';

import { Icon, IconTypes } from '@grid-is/icon';
import { OutsideClickHandler } from '@grid-is/outside-click-handler';

import { isMobile } from '@/utils';

import { Tag } from '../Tag';

import styles from './SelectInline.module.scss';

export type SelectInlineListItem = {
  label: string,
  value: string | boolean,
  icon: IconTypes,
  hasDividerOnTop?: boolean,
  hasProPill?: boolean,
  href?: string,
}

export type SelectInlineProps = {
  items: SelectInlineListItem[],
  value: string | boolean,
  onChange?: (selected: SelectInlineListItem) => void,
}

export const SelectInline = ({ items, value, onChange }: SelectInlineProps) => {
  const [ open, setOpen ] = useState(false);
  const [ position, setPosition ] = useState({ top: 0, left: 0 });
  const [ activeElement, setActiveElement ] = useState(0);
  const ref = useRef<HTMLButtonElement>(null);
  const buttonHeight = 36;

  useEffect(() => {
    const index = items.findIndex(item => item.value === value);
    setActiveElement(index);
  }, [ items, value ]);

  const onClose = () => {
    setOpen(false);
    setPosition({ top: 0, left: 0 });
  };

  const onClick = (index: number) => {
    if (index !== activeElement) {
      onChange?.(items[index]);
      setActiveElement(index);
    }
    onClose();
  };

  const openSelect = () => {
    const refRect = ref.current?.getBoundingClientRect();
    if (refRect) {
      setPosition({
        top: refRect.top - (activeElement * buttonHeight + 8),
        left: refRect.left,
      });
      setOpen(true);
    }
  };

  const item = items[activeElement];

  return (
    <>
      <div className={styles.selectInline} data-testid="closed">
        <button type="button" className={styles.active} onClick={openSelect} ref={ref}>
          {item?.label}
          <div className={styles.iconWrapper}>
            <Icon name="angle" direction="down" size={16} />
          </div>
        </button>
      </div>
      {open && ReactDOM.createPortal(
        <div className={csx(styles.selectInline, styles.inPortal, isMobile() && styles.mobile)} style={{ ...(!isMobile() && { ...position }) }}>
          <OutsideClickHandler
            onClickOutside={onClose}
            className={isMobile() ? styles.mobileOutsideClickHandler : undefined}
            >
            <div className={csx(styles.container, open && styles.open)} data-testid="open" data-ignoreoutsideclick>
              <ul>
                {items.map((item, index) => {
                  const selectedButton = index === activeElement;
                  return (
                    <li key={item.label} className={csx(item.hasDividerOnTop && styles.hasDividerOnTop)} data-obid={`select-inline-${item.label}`}>
                      {item.href ? (
                        <Link
                          href={item.href}
                          passHref
                          >
                          <a
                            target="_blank"
                            className={csx(selectedButton && styles.active)}
                            data-testid={selectedButton && 'selected'}
                            onClick={() => onClose()}
                            >
                            {item.icon && open && <Icon name={item.icon} size={16} className={styles.listIcon} />}
                            {item.label}
                            {item.hasProPill && <Tag label="PREMIUM" type="pro" className={styles.tagWrapper} />}
                          </a>
                        </Link>
                      ) : (
                        <button
                          type="button"
                          className={csx(selectedButton && styles.active)}
                          onClick={() => onClick(index)}
                          data-testid={selectedButton && 'selected'}
                          data-obid={`select-inline-${item.label}`}
                          >
                          {item.icon && open && <Icon name={item.icon} size={16} className={styles.listIcon} />}
                          {item.label}
                          {item.hasProPill && <Tag label="PREMIUM" type="pro" className={styles.tagWrapper} />}
                        </button>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </OutsideClickHandler>
        </div>, document.body)
      }
    </>
  );
};
