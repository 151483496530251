import { createContext, FC, useEffect } from 'react';
import { useInterpret } from '@xstate/react';
import { interpret, InterpreterFrom } from 'xstate';

import { sharingMachine } from './SharingMachine';
import type { SharingContext } from './types';

const initialService = interpret(sharingMachine);

export const SharingStateContext = createContext<{service: InterpreterFrom<typeof sharingMachine>}>({ service: initialService });

export const SharingStateContextProvider: FC<SharingContext> = ({ children, ...props }) => {
  const service = useInterpret(sharingMachine, { context: props });
  const { send } = service;
  const { documentTitle, documentId, statefulUrl, isDocumentOwner, hasAlteredState, suggestedTitle } = props;
  useEffect(() => {
    send('UPDATE_SENSITIVE_PROPS', { hasAlteredState, documentTitle, documentId, statefulUrl, isDocumentOwner, suggestedTitle });
  }, [ send, hasAlteredState, documentTitle, documentId, statefulUrl, isDocumentOwner, suggestedTitle ]);

  return (
    <SharingStateContext.Provider value={{ service }}>
      {children}
    </SharingStateContext.Provider>
  );
};
